import React, { useState, useEffect } from 'react'
import { Form, Input, Space, message } from 'antd'
import cls from 'classname'
import { ReactComponent as Arrow} from '../../Images/landing_new/arrow.svg';
import Button from '../../Components/Button'

const initialState = {
  email: false,
  password: false,
}

function LoginForm(props) {
  const { error, resetError, onSubmit } = props;

  const [form] = Form.useForm();

  const [active, setActive] = useState(false);
  const [focused, setFocused] = useState(initialState)

  const handleBlur = (field) => {
    const data = form.getFieldsValue();
    if (!data[field]) {
      setFocused(prev => ({...prev, [field]: false}))
    }
  }

  const onFinish = values => {
    onSubmit(values);
  };

  useEffect(() => {
    if (error && error.status_code !== 404 && !error.is_blocked) {
      setActive(true);
    } else if (error?.status_code === 404) {
      message.error('The link has been already used or expired. Please login again.', 7);
    }
    resetError();
  }, [error])

  useEffect(() => {
    if (active) {
      setTimeout(() => {
        setActive(false);
      }, 3000);
    }
  }, [active])

  return (
    <Form
      onFinish={onFinish}
      form={form}
      className="login-form-new"
      layout="vertical"
      style={{
        width: '100%',
      }}
      requiredMark={false}
    >

      <Form.Item
        name="email"
        label={<div className={cls('', {'active': focused['email']})}>Email address</div>}
        rules={[
          { type: 'email', message: 'Invalid email format' },
          { required: true, message: 'This field is required' },
          // { min: 5, message: 'Field must contain at least 5 characters' },
        ]}
        onKeyPress={e=>{if(e.charCode === 32) e.preventDefault()}}
        // onBlur={e=> Utils.clearSpaces(e, 'email', form.setFieldsValue)}
        style={{marginBottom: 20}}
      >
        <Input
          maxLength={100}
          className='custom-input'
          onFocus={()=> setFocused(prev => ({...prev, ['email']: true}))}
          onBlur={()=> handleBlur('email')}
        />
      </Form.Item>

      <Form.Item
        name="password"
        label={<div className={cls('', {'active': focused['password']})}>Password</div>}
        rules={[
          { required: true, message: "This field is required" },
          { pattern: /^[a-zA-Z0-9!@#$]+$/, message: 'Invalid character' },
          { min: 8, message: 'Field must contain at least 8 characters' },
        ]}
        style={{marginBottom: 20}}
      >
        <Input.Password
          maxLength={100}
          autoComplete="new-password"
          className='custom-input'
          onFocus={()=> setFocused(prev => ({['email']: true, ['password']: true}))}
          onBlur={()=> handleBlur('password')}
        />
      </Form.Item>
      {
        active &&
          <Space>
            <span className="error-message">Incorrect login or password</span>
          </Space>
      }
      <Button
        type="primary"
        className='landing primary-green withArrow h-50'
        text={
          <div className='button-text-wrapper'>
            <div>Log in</div>
            <div>
              <Arrow className="button-arrow" />
            </div>
          </div>
        }
        style={{width: '100%', marginTop: 40}}
        htmlType="submit"
      />
    </Form>
  )
}

export default LoginForm
