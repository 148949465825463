import React, {useState, useEffect} from 'react'
import {useSelector} from 'react-redux'
import {Row, Col, Modal, Typography, Form, Select, Input, message} from 'antd'
import cls from 'classname'
import {useDispatch} from 'react-redux'
import Button from '../../../../Components/Button'
import { ReactComponent as Arrow} from '../../../../Images/landing_new/arrow.svg';
import AuthActions from '../../../../Redux/AuthRedux'

import './modal.less'
import Images from '../../../../Images';

const { Title, Text } = Typography;
const { TextArea } = Input;

const options = [
  {label: 'A company seeking capital', value: 1},
  {label: 'An advisory firm', value: 2},
  {label: 'A senior industry executive', value: 3},
  {label: 'An investor', value: 4},
  {label: 'Other', value: 5},
];

const initialState = {
  email: false,
  linked: false,
  details: false,
}

export const ContactFormModal = ({setIsModalOpen, isModalOpen, fetching, ...props}) => {

  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [focused, setFocused] = useState(initialState);

  const result = useSelector(state => state.auth.result);

  const handleCancel = () => {
    form.resetFields();
    setFocused(initialState)
    setIsModalOpen(false);
  };

  const handleBlur = (field) => {
    const data = form.getFieldsValue();
    if (!data[field]) {
      setFocused(prev => ({...prev, [field]: false}))
    }
  }

  const onFinish = values => {
    const data = values;
    dispatch(AuthActions.contactRequest(data))
  }

  useEffect(() => {
    if (result?.type === 'contactRequest') {
      setIsModalOpen(false);
    }
  }, [result]);

  return (
    <Modal
      visible={isModalOpen}
      // visible={true}
      afterClose={()=> {
        form.resetFields();
        setFocused(initialState)
      }}
      onCancel={handleCancel}
      className='contact-form-modal'
      footer={false}
      width={500}
      centered
      closable={false}
      maskClosable={true}
    >
      <div style={{width: '100%', maxHeight: '100%'}}>
        <Title className='modal-title'>Thank you for contacting us.</Title>
        <Text className='modal-sub-title'>
          Please provide some details about your inquiry, and we will be in touch to discuss how we can assist you.
        </Text>
      </div>

      <Form onFinish={onFinish}
          // onFinishFailed={(errorInfo)=> console.log('onFinishFailed', errorInfo)}
          // validateTrigger="onSubmit"
          requiredMark={false}
          layout="vertical"
          className='contact-form'
          form={form}
          style={{
            margin: '30px 0 0',
            width: '100%',
          }}
          // initialValues={{}}
        >
          <Row gutter={[0, 20]}>
            <Col span={24}>
              <Form.Item
                name="email"
                label={<div className={cls('', {'active': focused['email']})}>Email address</div>}
                rules={[
                  {type: 'email', message: 'The email you entered is incorrect.'},
                  {required: true, message: 'Please enter your email'},
                ]}
              >
                <Input
                  className='custom-input'
                  onFocus={()=> setFocused(prev => ({...prev, ['email']: true}))}
                  onBlur={()=> handleBlur('email')}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="linkedin_profile"
                label={<div className={cls('', {'active': focused['linkedin_profile']})}>LinkedIn Profile:</div>}
                // rules={[
                //   {
                //     required: true,
                //     message: 'Please enter your linkedin profile'
                //   },
                // ]}
                style={{marginBottom: 14}}
              >
                <Input
                  className='custom-input'
                  onFocus={()=> setFocused(prev => ({...prev, ['linkedin_profile']: true}))}
                  onBlur={()=> handleBlur('linkedin_profile')}
                />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                name="user_affiliation"
                label=""
                // rules={[
                //   {
                //     required: true,
                //     message: 'Please choose'
                //   },
                // ]}
              >
                <Select
                  suffixIcon={<img src={Images.arrow_down} alt='' />}
                  placeholder={'I am / I represent:'}
                  allowClear
                  // open={true}
                  className='custom-select-new'
                  // popupClassName='custom-select-new-dropdown'
                >
                  {
                    options?.map(({label, value}) => (
                      <Select.Option value={value} key={value} name={label}>
                          <span className='custom-select-label'>{label}</span>
                      </Select.Option>
                    ))
                  }
                </Select>
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                name="details"
                className='textarea-form-item'
                label={<div className={cls('', {'active': focused['details']})}>Tell us briefly why you`re reaching out:</div>}
                rules={[]}
                // style={{marginBottom: 36}}
              >
                <TextArea
                  className='custom-input textarea'
                  onFocus={()=> setFocused(prev => ({...prev, ['details']: true}))}
                  onBlur={()=> handleBlur('details')}
                  rows={3}
                  maxLength={230}
                  autoSize={{
                    minRows: 1,
                    maxRows: 5,
                  }}
                />
              </Form.Item>
            </Col>


            <Col span={24}>
              <Button
                type="primary"
                className='landing primary-green withArrow h-50'
                text={
                  <div className='button-text-wrapper'>
                    <div>Submit</div>
                    <div>
                    <Arrow className="button-arrow" />
                    </div>
                  </div>
                }
                style={{width: '100%'}}
                htmlType="submit"
                disabled={fetching}
              />
            </Col>
          </Row>
        </Form>
    </Modal>
  )
}