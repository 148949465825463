import React from 'react'

import MainHeaderNew from './components/MainHeaderNew.js'
import {
  CaseStudyBlock,
  TestimonialBlock,
  Footer,
  GetInTouchBlock,
  SecondaryPageTopBlock,
  CollapsedBlock
} from './components/helpers.js'
import useAnimationEffects from './components/useAnimationEffects.js'

function InvestorsPage(props) {

  //animation for bg lines and lines in getintouch block
  useAnimationEffects('secondary_page');


	return (
		<div className="main-page-layout investors-page" id='scrollableElement'>
			<MainHeaderNew />
      <div className='main-content-new'>

        <SecondaryPageTopBlock index={3} />

        <CollapsedBlock />

        <GetInTouchBlock index={3} />

        <Footer />

      </div>
		</div>
	)
}

export default InvestorsPage;
