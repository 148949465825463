import { useEffect } from "react";

//for lines on background
export const scailing = (scrollableElement, page) => {
  const scrollTop = scrollableElement.scrollTop;
  const scaleFactor = Math.min(1.2, 1 + scrollTop / 1000);
  document.documentElement.style.setProperty('--scale-factor-1', scaleFactor);
};

//for lines on get in touch block
export const scailing2 = (scrollableElement) => {
  const block = document.getElementById('intouch-block');
  if (!block) return;

  const rect = block.getBoundingClientRect();
  // console.log(rect.top )
  const isVisible = rect.top >= -80 && rect.top <= 1600;
  if (isVisible) {
    const scrollTop = scrollableElement.scrollTop;
    const scaleFactor = Math.min(1.2, 1 + scrollTop / 1000);
    document.documentElement.style.setProperty('--scale-factor-2', scaleFactor);
  } else {
    document.documentElement.style.setProperty('--scale-factor-2', 1);
  }
};

const useAnimationEffects = (page) => {
  useEffect(() => {
    const element = document.getElementById("scrollableElement");
    if (!element) return;

    const checkVisibility = () => {
      let targetElement = document.querySelector(".content-wrapper");
      if (page === 'secondary_page') {
        targetElement = document.querySelector(".testimonial-block");
      }
      if (targetElement) {
        const rect = targetElement.getBoundingClientRect();
        const isVisible = rect.top >= 0 && rect.top <= 260;
      }

      scailing(element, page);
      scailing2(element);
    };

    element.addEventListener("scroll", checkVisibility);

    const animateBlock = () => {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              entry.target.classList.add("visible");
              observer.unobserve(entry.target);
            }
          });
        },
        { threshold: 0.1, rootMargin: '0px 0px 50px 0px' }
      );

      document.querySelectorAll(".animated-block").forEach((block) => observer.observe(block));
    };

    animateBlock();

    return () => {
      element.removeEventListener("scroll", checkVisibility);
    };
  }, []);
};

export default useAnimationEffects;