import React, { useEffect, useState } from 'react'
import { Layout, Spin, Space, message } from 'antd'
import { connect } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'

import AuthActions from '../../Redux/AuthRedux'
// import errorHandler from '../../Utils/errorHandler.js'
import LoginForm from '../../Components/Forms/LoginForm'
import { ReactComponent as Logo} from '../../Images/landing_new/logo-new-landing.svg';
import './index.less'
import { ContactFormModal } from '../Landing/components/Modal/ContactFormModal'


function LoginPageNew(props) {
  const { error, fetching, result, location } = props;
  const { state } = location;

  const history = useHistory();
  const [isModalOpen, setIsModalOpen] = useState(false);

  function handleSubmit(data) {
    props.signIn(data.email, data.password, state?.pathname, state?.hash);
  }

  // useEffect(() => {
  //   if (error) {
  //     errorHandler(error, props.resetError);
  //   }
  // }, [error]);

  return (
    <div className="login-page-layout">
      <div className='login-content-new'>
        <div className='left-part'>
          <Link className="logo-link-new" to="/">
            <Logo className='main-logo'/>
          </Link>

          <div className='login-form-wrapper'>
            <LoginForm
              error={error}
              resetError={props.resetError}
              onSubmit={handleSubmit}
            />
            <div className='bottom-block'>
              <div>
                Don't have an account?&nbsp;
                <span
                  className='link-contact-us'
                  onClick={()=> setIsModalOpen(true)}
                >
                  Contact us
                </span>
              </div>
              <Link className='green' to="/reset-password">Forgot Password?</Link>
            </div>

          </div>
        </div>
        <div className='right-part'></div>
      </div>
      <ContactFormModal
        setIsModalOpen={setIsModalOpen}
        isModalOpen={isModalOpen}
      />
    </div>
  )
}


const mapStateToProps = (state) => {
  return {
    error: state.auth.error,
    fetching: state.auth.fetching,
    result: state.auth.result,
  }
}

const mapDispatchToProps = (dispatch) => ({
  signIn: (email, password, pathname, hash) => dispatch(AuthActions.signInRequest(email, password, pathname, hash)),
  resetError: () => dispatch(AuthActions.authErrorReset()),
})

export default connect(mapStateToProps, mapDispatchToProps)(LoginPageNew)
