
import logoNewLanding from './logo-new-landing.svg'
import vitol from './carousel/vitol.png'
import dws from './carousel/dws.png'
import goldman from './carousel/goldman.png'
import lazard from './carousel/lazard.png'
import squared from './carousel/squared.png'
import slide_1 from './slider/slide_1.jpg'
import slide_2 from './slider/slide_2.jpg'
import slide_3 from './slider/slide_3.jpg'
import slide_4 from './slider/slide_4.jpg'
import sector_1 from './sectors/sector_1.jpg'
import sector_2 from './sectors/sector_2.jpg'
import sector_3 from './sectors/sector_3.jpg'
import sector_4 from './sectors/sector_4.jpg'
import sector_1w from './sectors/sector_1w.jpg'
import sector_2w from './sectors/sector_2w.jpg'
import sector_3w from './sectors/sector_3w.jpg'
import sector_4w from './sectors/sector_4w.jpg'
import home from './sectors/home.svg'
import heart from './sectors/circle_heart.svg'
import circle_heart from './sectors/heart.svg'
import comp from './sectors/comp.svg'
import earth from './sectors/earth.svg'
import i5g from './sectors/5g.svg'
import cloud from './sectors/cloud.svg'
import sputnic from './sectors/sputnic.svg'
import wheel from './sectors/wheel.svg'
import mobility from './sectors/mobility.svg'
import fleets from './sectors/fleets.svg'
import bridge2 from './sectors/bridge.svg'
import wind from './sectors/wind.svg'
import energy from './sectors/energy.svg'
import bin from './sectors/bin.svg'
import infr from './sectors/infr.svg'
import why_idn from './why-idn.jpg'
import connection from './connection.svg'
import win from './win.svg'
import trust from './trust.svg'
import linked from './linked.svg'
import romb from './romb_bg.png'
import ceo from './ceo.png'
import teodor from './teodor.png'
import stefano from './stefano.png'
import fernando from './fernando.png'
import bernhard from './bernhard.png'
import samir from './samir.png'
import fiona from './fiona.png'
import image_companies from './image_companies.jpg'
import imageAdvisors from './imageAdvisors.jpg'
import image_industry from './image_industry.jpg'
import image_investors from './image_investors.jpg'
import case_study_1 from './case_study_1.jpg'
import case_study_4 from './case_study_4.jpg'
import case_study_2 from './case_study_2.jpg'
import case_study_5 from './case_study_5.jpg'
import case_study_3 from './case_study_3.jpg'
import case_study_6 from './case_study_6.jpg'
import outcome from './outcome.png'
import approach from './approach.png'
import gsc from './track_records/gcn.svg'
import exa from './track_records/exa.svg'
import squared2 from './track_records/squared.svg'
import divider from './track_records/divider.svg'
import global from './track_records/global.svg'
import dws2 from './track_records/dws2.svg'
import vitol2 from './track_records/vitol.svg'
import vpi from './track_records/vpi.svg'
import quantitas from './track_records/quantitas.svg'
import goldman2 from './track_records/goldman.svg'
import verdalia from './track_records/verdalia.svg'
import hba from './track_records/hba.svg'
import lazard2 from './track_records/lazard.svg'
import collective from './track_records/collective.svg'
import foee from './track_records/foee.svg'
import cubierta from './track_records/cubierta.svg'
import arrow_down from './arrow_down.svg'

export const ImagesLanding = {
  //track record
  squared2, exa, gsc, divider, dws2, global, vitol2, vpi, quantitas,
  goldman2, verdalia, hba, lazard2, collective, foee, cubierta,

  //SectorsBlock - sectors page
  home, comp, earth, i5g, heart, circle_heart, cloud,
  sputnic, wheel, mobility, fleets, bridge2, wind, energy,
  bin, infr,

  logoNewLanding,
  vitol,
  dws,
  goldman,
  lazard,
  squared,
  slide_1,
  slide_2,
  slide_3,
  slide_4,
  sector_1,
  sector_2,
  sector_3,
  sector_4,
  sector_1w,
  sector_2w,
  sector_3w,
  sector_4w,

  why_idn,
  connection,
  win,
  trust,
  linked,
  romb,
  ceo,
  teodor,
  stefano,
  fernando,
  bernhard,
  samir,
  fiona,
  image_companies,
  imageAdvisors,
  image_industry,
  image_investors,
  case_study_1,
  case_study_4,
  case_study_2,
  case_study_5,
  case_study_3,
  case_study_6,
  outcome,
  approach,

  arrow_down,
}