import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import cls from 'classname'
import { Typography, message } from 'antd'

import Images from '../../Images'
import MainHeaderNew from './components/MainHeaderNew.js'

import './MainPageNew.less'
import MainPageCarousel from './components/MainPageCarousel.js'
import { ButtonWithArrow, TestimonialBlock, Footer, GetInTouchBlock, ServicesBlock, UniqueBlock } from './components/helpers.js'
import { ReactComponent as Arrow} from '../../Images/landing_new/arrow.svg';
import useAnimationEffects from './components/useAnimationEffects.js'
// import errorHandler from '../../Utils/errorHandler.js'
// import AuthActions from '../../Redux/AuthRedux'

const {Title, Text} = Typography

const dataSectors = [
  {
    title: <span>Energy & <br /> Environment</span>,
    image: Images.sector_1,
    subSectors: ['Clean generation & storage', 'Energy efficiency', 'Waste & circular economy', 'Utilities & Trad. Infrastructure'],
    icons: ['wind', 'energy', 'bin', 'infr'],
  },
  {
    title: <span>Transport & <br /> Logistics</span>,
    image: Images.sector_2,
    subSectors: ['Logistics & storage', 'Clean mobility', 'Fleets decarbonization', 'Trad. Transport infrastructure'],
    icons: ['wheel', 'mobility', 'fleets', 'bridge2'],
  },
  {
    title: <span>Telecom & <br/> Data Infrastructure</span>,
    image: Images.sector_3,
    subSectors: ['Fibre connectivity', 'Towers & 5G-related', 'Data centres', 'New telco infrastructure'],
    icons: ['earth', 'i5g', 'cloud', 'sputnic'],
  },
  {
    title: <span>Social & <br/> Healthcare</span>,
    image: Images.sector_4,
    subSectors: ['Healthcare clinics', 'Education businesses', 'Elderly & social care', 'Social Infra PPPs'],
    icons: ['home', 'comp', 'heart', 'circle_heart'],
  },
]

function MainPage({
  result,
  error,
  ...props
}) {
	const [animate, setAnimate] = useState(false);
	const [activeSlide, setActiveSlide] = useState(0);
	const [isSliderVisible, setIsSliderVisible] = useState(false);
	const [isVisibleTop, setIsVisibleTop] = useState(false);

  const maxSlidesIndex = 3;

  const sliderElementRef = useRef(null);
  const topElementRef = useRef(null);
  const videoRef = useRef(null);

  const helpRef = useRef({
    firstViewed: false,
    autoplay: true,
    video: null,
  })

  useAnimationEffects();

  const handleTimeUpdate = () => {
    const video = videoRef.current;
    if (video.duration - video.currentTime <= 4) {
      video.pause();
    }
  };

  const restartVideo = () => {
    const video = videoRef.current;
    video.currentTime = 0;
    video.play();
  };

  const checkVisibility = () => {
    if (sliderElementRef.current) {
      const rect = sliderElementRef.current.getBoundingClientRect();
      const isInViewport = (
        rect.top < window.innerHeight &&
        rect.bottom > 0 &&
        rect.left < window.innerWidth &&
        rect.right > 0
      );
      setIsSliderVisible(isInViewport);
    }
    if (topElementRef.current) {
      const rect = topElementRef.current.getBoundingClientRect();
      const isInViewport = (
        rect.top < window.innerHeight &&
        rect.bottom > 0 &&
        rect.left < window.innerWidth &&
        rect.right > 0
      );
      setIsVisibleTop(isInViewport);
    }
  };

  useEffect(() => {
    const timerTopImageAnimation = setTimeout(() => {
      setAnimate(true);
    }, 600);

    checkVisibility();
    const scrollContainer = document.getElementById('scrollableElement');
    scrollContainer.addEventListener('scroll', checkVisibility);

    return () => {
      if (timerTopImageAnimation) clearTimeout(timerTopImageAnimation);
      scrollContainer.removeEventListener('scroll', checkVisibility);
    };
  }, []);

  useEffect(() => {
    if (isVisibleTop) {
      restartVideo();
    }
  }, [isVisibleTop]);

  useEffect(() => {
    let timer;

    if (activeSlide > 0 && activeSlide !== 3 && helpRef.current.autoplay) {
      timer = setTimeout(() => {
        setActiveSlide((prev) => (prev < maxSlidesIndex ? prev + 1 : prev));
      }, 8000);
    } else if (activeSlide === 0 && isSliderVisible) {
      timer = setTimeout(() => {
        setActiveSlide(1);
      }, 8000);
    } else if (activeSlide === 3) {
      const element = document.getElementById('scrollableElement');
      timer = setTimeout(() => {
        element.style.overflow = 'auto';
      }, 8000);
      helpRef.current.autoplay = false;
    }

    return () => clearTimeout(timer);
  }, [activeSlide, isSliderVisible]);

  // useEffect(() => {
  //   if (error) {
  //     errorHandler(error, props.resetError);
  //   }
  // }, [error]);

	return (
		<div className="main-page-layout" id='scrollableElement'>
			<MainHeaderNew />
      <div className='main-content-new'>
        <div className={cls("main-top-block", { animate })} ref={topElementRef}>
          <video
            ref={videoRef}
            className="video-bg"
            // src="https://api.idn.ltd/api/media/WhatsApp_Video_2024-12-10_at_18.46.55_848a44d7.mp4"
            src="https://api.idn.ltd/api/media/HD_1.mp4"
            // autoPlay
            muted
            // loop
            playsInline
            onTimeUpdate={handleTimeUpdate}
          >
          </video>
          <div className='main-top-title-block'>
            <Title className='title font-60 white'>
              The Infrastructure <br/> Deals Network
            </Title>
            <Title className='title sub-title font-28 white'>
              Your Trusted Partner for accessing private equity capital in Europe
            </Title>
          </div>
          <div className="mask"></div>
          <div className="gradient"></div>
        </div>

        <div className='main-about-us-block animated-block'>
          <div className='block-title'>About us</div>
          <Title className='title'>
            IDN is the leading M&A platform connecting the infrastructure ecosystem across Europe
          </Title>
          <Text className='text center'>
            We've successfully facilitated transactions with firms and funds associated with:
          </Text>
          <MainPageCarousel />
        </div>

        <div className='main-our-services-block animated-block'>
          <div className='block-title'>Our services</div>
          <Title className='title'>
            We partner with companies, M&A advisors, and senior industry leaders, aligning private equity capital with their strategic ambitions and needs.
          </Title>
          <ServicesBlock />
        </div>

        <UniqueBlock index={activeSlide} setActiveSlide={setActiveSlide} maxSlidesIndex={maxSlidesIndex}
          sliderElementRef={sliderElementRef}
        />

        <div className='main-sectors-block animated-block'>
          <div className='content-wrapper'>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
              <Title className='title'>Sectors</Title>
              <Link to="/sectors">
                <ButtonWithArrow
                  text={'Learn more'}
                  style={{marginTop: 10}}
                  className={'sectors-new'}
                />
              </Link>
            </div>
            <div className='sectors-img-wrapper' >
              {dataSectors.map((el, i) => (
                <div key={i} className='sector-item'>
                  <img src={el.image} alt='' width={'100%'} style={{height: '100%'}}/>
                  <div className='img-mask'></div>
                  <ButtonWithArrow
                    text={null}
                    className={'arrow-sector'}
                  />
                  <div className='sector-title white'>{el.title}</div>
                  {/* <div className='arrow-wrapper-learn-more'>
                    <div className='learn-more white'>Learn more</div>
                    <Arrow className="arrow" />
                  </div> */}
                  <div className='sub-sectors-wrapper'>
                    {el.subSectors.map((elem, index) => {
                      return (
                        <div className='sub-sector' key={index}>
                          <img src={Images[el.icons[index]]} alt='' width={22} />
                          <div>{elem}</div>
                        </div>
                      )}
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <TestimonialBlock index={0} className={'main-page'}/>

        <GetInTouchBlock index={0}/>

        <Footer />

      </div>

		</div>
	)
}


const mapStateToProps = (state) => {
  return {
    result: state.auth.result,
    // error: state.auth.error,
  }
}

const mapDispatchToProps = (dispatch) => ({
  // resetError: () => dispatch(AuthActions.authErrorReset()),
})

export default connect(mapStateToProps, mapDispatchToProps)(MainPage)
