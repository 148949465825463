import React from 'react'

import MainHeader from './MainHeader.js'
import MainFooter from './MainFooter.js'
// import './MainPage.less'

function PrivacyPolicyPage(props) {

  return (
    <div className="page5 twofixed privacy">
      <MainHeader />
      <div className="Terms privacy">
        <h1 className="terms--head1">Website Privacy Policy</h1>
        <div style={{marginBottom: 16}}><em>Privacy Policy updated 26 October 2021</em></div>
        <h2><span className="head2--indent">1.</span> INTRODUCTION</h2>
        <ul>
          <li><span className="number--indent">1.1</span> The Infrastructure Deals Network Ltd is a private limited company incorporated and registered in England and Wales with company number 12894864, our registered office is at Kemp House, 160 City Road, London, United Kingdom, EC1V 2NX (<strong>“IDN”</strong>, <strong>“us”</strong>, <strong>“we”</strong> or <strong>“our”</strong>).
          </li>
          <li><span className="number--indent">1.2</span> We respect your privacy and are committed to protecting your Personal Data. This privacy policy (the <strong>“Policy”</strong>) will inform you how we will use and disclose your Personal Data when you visit our Platform (regardless of where you visit it from) and use our Services. We will explain your privacy rights and how the law in our jurisdiction protects you.</li>
          <li><span className="number--indent">1.3</span> Capitalised terms used herein but not otherwise defined shall have the meaning given in IDN’s terms of use which can be found at
          <a className="site-link" href="https://www.idn.ltd/terms-of-use" target="_blank"> https://www.idn.ltd/terms-of-use </a>
          (the <strong>“Terms”</strong>). Capitalised terms used herein that are not defined in the Terms shall have the meaning given in the Schedule hereto.
          </li>
        </ul>
        <h2><span className="head2--indent">2.</span> IMPORTANT INFORMATION</h2>
        <ul>
          <li><span className="number--indent">2.1</span> It is important that you read this Policy together with any other policy or fair processing notice which we may publish on the Platform from time to time. This Policy supplements any other notices and is not intended to override them unless specifically stated.
          </li>
          <li><span className="number--indent">2.2</span> This Policy aims to give you information on how we will collect and process your Personal Data as you interact with and use our Platform, including any Personal Data you may provide when you sign up for Services.
          </li>
          <li><span className="number--indent">2.3</span> The Platform is not intended for use by any Child. We do not accept any liability where we have unknowingly processed Personal Data relating to any Children.
          </li>
          <li><span className="number--indent">2.4</span> You have the right to make a complaint at any time to the Information Commissioner's Office (ICO), the UK supervisory authority for data protection issues (www.ico.org.uk). We would, however, appreciate the chance to deal with your concerns before you approach the ICO so please contact us in the first instance.
          </li>
        </ul>
        <h2><span className="head2--indent">3.</span> DATA CONTROLLER AND PROCESSOR</h2>
        <ul>
          <li><span className="number--indent">3.1</span> IDN is a Data Controller in respect of certain Personal Data provided via the Platform and/or via other communications with IDN including without limitation by email by its Users in respect of themselves.
          </li>
          <li><span className="number--indent">3.2</span> IDN is the Data Processor in respect of any Personal Data processed by IDN on behalf of any User. This would include Personal Data in respect of any Authorised Person of a User and/or any person connected with a Target.
          </li>
        </ul>
        <h2><span className="head2--indent">4.</span> CHANGES TO YOUR PERSONAL DATA</h2>
        <ul>
          <li><span className="number--indent"></span> It is important that the Personal Data we hold about you is accurate and current. Please notify us informed if any of your Personal Data changes during your relationship with us. This can be done by updating your details on your Account or by contacting us using the details below. Any previous Personal Data held by us in respect of you will be deleted as soon as reasonably practical.
          </li>
        </ul>
        <h2><span className="head2--indent">5.</span> THIRD-PARTY LINKS</h2>
        <ul>
          <li><span className="number--indent">5.1</span> The Platform may include links to third-party websites, plug-ins and applications. Clicking on any of these links or enabling those connections may allow third parties not associated with IDN to collect or share data about you. We do not control these third-party websites and are not responsible for their privacy statements.
          </li>
          <li><span className="number--indent">5.2</span> We encourage you to read the privacy policy of every website you visit, but, for the avoidance of doubt, IDN accepts no liability for any data that has been unlawfully processed by third parties as a result of you accessing these links.
          </li>
          <li><span className="number--indent">5.3</span> IDN will regularly use a third-party software for storing email address of our customers. To view the privacy policy for the third-party software please visit
          <a className="site-link" href="https://www.hubspot.com/" target="_blank"> https://www.hubspot.com/</a>
          </li>
        </ul>
        <h2><span className="head2--indent">6.</span> THE DATA WE COLLECT ABOUT YOU</h2>
        <ul>
          <li><span className="number--indent">6.1</span> We may collect, use, store and transfer different kinds of Personal Data about you, which includes but is not limited to, the following categories:
          </li>
          <li>
              <div className="definition">
                <div className="def-title">“Aggregated Data”</div>
                <div className="def-text">data which is derived from your Personal Data but is not considered Personal Data under the Data Protection Legislation. This is because the data does not directly or indirectly reveal your identity. For example, “20% of our Users have accessed this functionality”;</div>
              </div>
              <div className="definition">
                <div className="def-title">“Contact Data”</div>
                <div className="def-text">your email address, telephone number, billing address and address for service;</div>
              </div>
              <div className="definition">
                <div className="def-title">“Financial Data”</div>
                <div className="def-text">your bank account information and/or payment card details;</div>
              </div>
              <div className="definition">
                <div className="def-title">“Identity Data”</div>
                <div className="def-text">your first name, last name and date of birth (to the extent processed by us);</div>
              </div>
              <div className="definition">
                <div className="def-title">“Profile Data”</div>
                <div className="def-text">your username and password and transaction history (including without limitation records of any Advisor and/or Investor with whom you have communicated, and any Opportunities submitted to the Platform and/or Introduced to you);</div>
              </div>
              <div className="definition">
                <div className="def-title">“Special Categories of Personal Data”</div>
                <div className="def-text">Personal Data which is sensitive in nature and requires a higher level of protection. We do not collect Special Categories of Personal Data save that we may as part of our anti-money laundering and you’re your client checks collect and/or receive information on criminal convictions and offences;</div>
              </div>
              <div className="definition">
                <div className="def-title">“Transaction Data”</div>
                <div className="def-text">data that includes details about any payments to and from you;</div>
              </div>
              <div className="definition">
                <div className="def-title">“Technical Data”</div>
                <div className="def-text">internet protocol (IP) addresses, your log in data, browser type and version, time zone settings and location, browser plug-in types and versions, operating system and platform, and other technology on the devices you use to access the Platform; and</div>
              </div>
              <div className="definition">
                <div className="def-title">“Usage Data”</div>
                <div className="def-text">means information about how you use the Platform and our Services.</div>
              </div>
          </li>
          <li><span className="number--indent">6.2</span> We may also collect, use, and share Aggregated Data such as statistical or demographic data for any purpose. For example, we may aggregate your Usage Data to calculate the percentage of Users accessing specific functionality on our Platform. This will help us to ensure that we are responding to the preferences of our Users and deliver the best possible User experience. However, if we combine or connect Aggregated Data with your Personal Data in any way so that it can directly or indirectly identify you, the combined data will be considered Personal Data which will only be used in accordance with this Policy.
          </li>
        </ul>
        <h2><span className="head2--indent">7.</span> HOW IS YOUR PERSONAL DATA COLLECTED?</h2>
        <ul>
          <li><span className="number--indent"></span> We use different methods to collect Personal Data from and about you including through:
          </li>
          <li>
            <ul>
              <li><span className="number--indent">a.</span><strong>Direct interactions.</strong> You may give us your Identity, Contact and Financial Data when you create an Account, as you use our Services and/or by corresponding with us by post, phone, email or via our Platform.
              </li>
              <li><span className="number--indent">b.</span><strong>Automated technologies or interactions.</strong> As you interact with us via the Platform, we may automatically collect Technical Data about your equipment, browsing actions and pattern through mediums such as recording systems, server logs and other similar technologies. We collect this Personal Data by using server logs and other similar technologies.
              </li>
              <li><span className="number--indent">c.</span><strong>Third parties or publicly available sources.</strong> We may receive Personal Data about you from various third parties and public sources.
              </li>
            </ul>
          </li>
        </ul>
        <h2><span className="head2--indent">8.</span> WHAT IF YOU DO NOT PROVIDE PERSONAL DATA?</h2>
        <ul>
          <li><span className="number--indent">8.1</span> Where we need to collect Personal Data by law, or under the terms of the contract we have with you and you fail to provide that Personal Data when requested, we may not be able to enter into or perform our contract with you. We may be unable to create an Account for you with the Platform and/or to provide our Services to you.
          </li>
          <li><span className="number--indent">8.2</span> We accept no liability for any losses arising from any inability to fulfil any contractual requirement as a result of your failure to provide any Personal Data which we require.
          </li>
        </ul>
        <h2><span className="head2--indent">9.</span> PURPOSE FOR WHICH WE WILL USE YOUR PERSONAL DATA</h2>
        <ul>
          <li><span className="number--indent">9.1</span> In the table below, we have set out, the ways we plan to use your Personal Data, and the legal basis on which we will be relying. Our Legitimate Interests have been identified where appropriate. The table is not intended to be exhaustive.
          </li>
          <li><span className="number--indent">9.2</span> We may process your Personal Data for more than one lawful ground depending on the specific purpose for which we are using your Personal Data. We encourage you to contact us should you require any further details about the grounds we rely on.
          </li>
        </ul>
        <table className="first--table privacy">
          <tbody>
            <tr>
              <th>Purpose/Activity</th>
              <th>Type of data</th>
              <th>Lawful Basis for Processing Including Basis of Legitimate Interest</th>
            </tr>
            <tr>
              <td>
                <p>To register you as a User and create an Account for you on our Platform</p>
              </td>
              <td>
                <p>(a) Identity Data</p>
                <p>(b) Contact Data</p>
                <p>(c) Profile Data</p>
                <p>(d) Financial Data</p>
              </td>
              <td>
                <p>Performance of a contract with you</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>To enable you to use the Platform and the Services</p>
              </td>
              <td>
                <p>(a) Identity Data</p>
                <p>(b) Contact Data</p>
                <p>(c) Profile Data</p>
                <p>(d) Financial Data</p>
                <p>(e) Transaction Data</p>
              </td>
              <td>
                <p>Performance of a contract with you</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>To enforce legal measures in relation to any fraud, theft or breach of confidentiality and non-circumvention provisions</p>
              </td>
              <td>
                <p>(a) Identity Data</p>
                <p>(b) Contact Data</p>
                <p>(c) Profile Data</p>
                <p>(d) Financial Data</p>
                <p>(e) Transaction Data</p>
                <p>(f) Technical Data</p>
              </td>
              <td>
                <p>Performance of a contract with you</p>
                <p>Necessary to comply with a legal obligation (in the case of fraud, money laundering and/or terrorist financing where such Personal Data may be shared with law enforcement and/or any other User</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>To manage our relationship with you and other Users</p>
              </td>
              <td>
                <p>(a) Identity Data</p>
                <p>(b) Contact Data</p>
                <p>(c) Profile Data</p>
                <p>(d) Transaction Data</p>
                <p>(e) Technical Data</p>
              </td>
              <td>
                <p>(a) Performance of a contract with you</p>
                <p>(b) Necessary to comply with a legal obligation</p>
                <p>(c) Necessary for our Legitimate Interests (to enforce payment obligations of Users of the Platform; to keep our records updated; to study how Users use our Services)</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>To provide you with information on our Services that you have indicated are of interest to you, or which we reasonably believe may be of interest to you</p>
              </td>
              <td>
                <p>(a) Identity Data</p>
                <p>(b) Contact Data</p>
                <p>(c) Profile Data</p>
              </td>
              <td>
                <p>(a) Necessary for our Legitimate Interests (to provide Services to Users; to promote our Services to Users)</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>To administer and protect our business and the Platform (including troubleshooting, data analysis, testing, system maintenance, support, reporting and hosting of data)</p>
              </td>
              <td>
                <p>(a) Identity Data</p>
                <p>(b) Contact Data</p>
                <p>(c) Technical Data</p>
              </td>
              <td>
                <p>(a) Necessary for our Legitimate Interests (for running our business; provision of administration and IT services; network security; to prevent fraud and in the context of a business reorganisation or group restructuring exercise)</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>To deliver relevant Platform content</p>
              </td>
              <td>
                <p>(a) Identity Data</p>
                <p>(b) Contact Data</p>
                <p>(c) Profile Data</p>
                <p>(d) Usage Data</p>
                <p>(e) Technical Data</p>
              </td>
              <td>
                <p>Necessary for our Legitimate Interests (to study how Users use our Services; to develop and grow our business)</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>To use data analytics to improve the Platform, Services, User relationships and experiences</p>
              </td>
              <td>
                <p>(a) Technical Data</p>
                <p>(b) Usage Data</p>
              </td>
              <td>
                <p>Necessary for our Legitimate Interests (to define types of Users for our Services; to keep our Platform updated and relevant; to develop our business)</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>To make suggestions and recommendations to you about Services that may be of interest to you</p>
              </td>
              <td>
                <p>(a) Identity Data</p>
                <p>(b) Contact Data</p>
                <p>(c) Profile Data</p>
                <p>(d) Technical Data</p>
                <p>(e) Usage Data</p>
              </td>
              <td>
                <p>Necessary for our Legitimate Interests (to develop our Services and grow our business in a way that is compatible with the preferences of Users)</p>
              </td>
            </tr>
          </tbody>
        </table>
        <br/>
        <br/>
        <h2><span className="head2--indent">10.</span> WHEN WILL WE DISCLOSE YOUR PERSONAL DATA?</h2>
        <ul>
          <li><span className="number--indent">10.1</span><strong>This clause related only to Personal Data. It does not relate to Confidential Information relating to any Opportunity, Target or Transaction which we may process from time to time. The use by us of Confidential Information is governed by our Platform Contracts.</strong>
          </li>
          <li><span className="number--indent">10.2</span> We may have to share your Personal Data with third parties, including but not limited to the following, and for the purposes set out in the table in Paragraph 10 above:
            <ul>
              <li><span className="number--indent">a.</span> payment providers;</li>
              <li><span className="number--indent">b.</span> analytics providers;</li>
              <li><span className="number--indent">c.</span> our hosting provider;</li>
              <li><span className="number--indent">d.</span> law enforcement authorities; and</li>
              <li><span className="number--indent">e.</span> third parties to whom we may choose to sell, transfer, or merge parts of our business or our assets or where we seek to acquire other businesses or merge with them. If a change takes place, the new owners may use your Personal Data under the same terms as set out in this Policy.</li>
            </ul>
          </li>
          <li><span className="number--indent">10.3</span> All third parties are required to respect the security of your Personal Data and to treat it in accordance with the law. We will not allow our third-party data processors to use your Personal Data for their own purposes. We require our third party data processors to enter into a data processing agreement and/or standard contractual clauses in compliance with the Data Protection Legislation.
          </li>
        </ul>
        <h2><span className="head2--indent">11.</span> TRANSFERS OF YOUR PERSONAL DATA OUTSIDE OF THE UK AND EU</h2>
        <ul>
          <li><span className="number--indent">11.1</span> Your information may be stored and processed in North America, the United Kingdom and the European Union.
          </li>
          <li><span className="number--indent">11.2</span> Some of our third party data processors are based outside of the UK and the European Union (EU) so their processing of your Personal Data will involve a transfer of data outside the UK and EEA.
          </li>
          <li><span className="number--indent">11.3</span> Whenever we transfer your Personal Data out of the UK:
            <ul>
              <li><span className="number--indent">a.</span> to the EU, we comply with the Data Protection Legislation to ensure that the Personal Data is processed in accordance with UK and EU law;</li>
              <li><span className="number--indent">b.</span> outside of the UK and EU, we ensure a similar degree of protection is afforded to it by ensuring we use specific contracts approved by the UK Government and European Commission which give Personal Data the same protection it has in Europe.</li>
            </ul>
          </li>
          <li><span className="number--indent">11.4</span> Please contact us if you want further information on the specific mechanism used by us when transferring your Personal Data out of the UK and EU.
          </li>
        </ul>
        <h2><span className="head2--indent">12.</span> HOW WE PROTECT YOUR PERSONAL DATA</h2>
        <ul>
          <li><span className="number--indent"></span> We have put in place appropriate security measures to prevent your Personal Data from being accidently lost, used, or accessed in any unauthorised way, altered, or disclosed. We limit access to your Personal Data to those employees, agents, contractors and other third party data processors who have a business need to know. They will only process your Personal Data on our instructions, and they are subject to a duty of confidentiality. We will notify you and any applicable regulator (as required by law to do so) of any suspected breach of your Personal Data.
          </li>
        </ul>
        <h2><span className="head2--indent">13.</span> THIRD PARTY MARKETING</h2>
        <ul>
          <li><span className="number--indent">13.1</span> We will get your express consent before we share your Personal Data with any other company which is not directly linked to us for marketing purposes. We do not anticipate that we will ever request your consent to share your Personal Data with any other company for marketing purposes.
          </li>
          <li><span className="number--indent">13.2</span> At any point, you can ask us or the third-parties to stop sending you marketing communications and to stop processing your Personal Data by following the opt-out links on emails or by contacting us. Where you have not contacted us directly with your updated preferences, we will continue to process your Personal Data until such time that we are informed directly of your withdrawal of consent.
          </li>
          <li><span className="number--indent">13.3</span> When you opt out of receiving any marketing communications, this will not apply to Personal Data provided to us.
          </li>
        </ul>
        <h2><span className="head2--indent">14.</span> HOW LONG WE USE YOUR PERSONAL DATA FOR</h2>
        <ul>
          <li><span className="number--indent">14.1</span> In accordance with Data Protection Legislation, we will only retain your Personal Data for as long as necessary to fulfil the purpose for which we collected it. This includes satisfying any legal, accounting or reporting requirements. Once these have been satisfied, the Personal Data will either be deleted or amended to the extent that it would be considered Anonymous Data.
          </li>
          <li><span className="number--indent">14.2</span> We will consider the nature and sensitivity of the Personal Data and any potential risk of harm from unauthorised use or disclosure of your Personal Data when determining the appropriate retention period. We will consider other means and applicable legal requirements for when we process your Personal Data.
          </li>
        </ul>
        <h2><span className="head2--indent">15.</span> UPDATING OUR POLICIES</h2>
        <ul>
          <li><span className="number--indent">15.1</span> We may periodically update this Policy and in the event we do, we will post any changes on this page and, if the changes are material, we will provide a more prominent notice by sending you an email notification to the email address which you have provided us.
          </li>
          <li><span className="number--indent">15.2</span> While we will notify you of any material changes to this Policy prior to the changes becoming effective. We encourage you to review this Policy periodically.
          </li>
        </ul>
        <h2><span className="head2--indent">16.</span> YOUR LEGAL RIGHTS</h2>
        <ul>
          <li><span className="number--indent">16.1</span> Under certain circumstances, you have rights under the Data Protection Legislation in relation to your Personal Data.
          </li>
          <li><span className="number--indent">16.2</span> You have the right to:
            <ul>
              <li><span className="letter--indent">a.</span><strong>Request access</strong>: to your Personal Data (commonly known as a "data subject access request"). This enables you to receive a copy of the Personal Data we hold about you;
              </li>
              <li><span className="letter--indent">b.</span> <strong>Request correction</strong>: of the Personal Data that we hold about you. This enables you to have any incomplete or inaccurate data we hold about you corrected, though we may need to verify the accuracy of the new data you provide to us;
              </li>
              <li><span className="letter--indent">c.</span> <strong>Request erasure</strong>: of your Personal Data. This enables you to ask us to delete or remove Personal Data where there is no good reason for us continuing to process it. You also have the right to ask us to delete or remove your Personal Data where you have successfully exercised your right to object to processing (see below), where we may have processed your information unlawfully or where we are required to erase your Personal Data to comply with the Data Protection Legislation. Note, however, that we may not always be able to comply with your request of erasure for specific legal reasons which will be notified to you, if applicable, at the time of your request;
              </li>
              <li><span className="letter--indent">d.</span> <strong>Object to processing</strong>: of your Personal Data where we are relying on a Legitimate Interest (or those of a third-party) and there is something about your particular situation which makes you want to object to processing on this ground as you feel it impacts on your fundamental rights and freedoms. You also have the right to object where we are processing your Personal Data for direct marketing purposes. In some cases, we may demonstrate that we have compelling legitimate grounds to process your information which override your rights and freedoms;
              </li>
              <li><span className="letter--indent">e.</span> <strong>Request restriction of processing</strong>: of your Personal Data. This enables you to ask us to suspend the processing of your Personal Data in the following scenarios:
                <ul>
                  <li><span className="number--indent">(i)</span> if you want to establish the data’s accuracy;</li>
                  <li><span className="number--indent">(ii)</span> where our use of the data is unlawful but you do not want us to erase it;</li>
                  <li><span className="number--indent">(iii)</span> where you need us to hold the data even if we no longer require it as you need it to establish, exercise or defend legal claims; or</li>
                  <li><span className="number--indent">(iv)</span> you have objected to our use of your data but we need to verify whether we have overriding legitimate grounds to use it.</li>
                </ul>
              </li>
              <li><span className="letter--indent">f.</span> <strong>Request the transfer</strong>: of your Personal Data to you or to a third party. We will provide to you, or a third party you have chosen, your Personal Data in a structured, commonly used, machine-readable format. Note that this right only applies to automated information which you initially provided consent for us to use or where we used the information to perform a contract with you. In addition, this does not provide you with routine access to our systems and processes and only the extraction of your Personal Data; and
              </li>
              <li><span className="letter--indent">g.</span> <strong>Withdraw consent at any time</strong>: where we are relying on consent to process your Personal Data. However, this will not affect the lawfulness of any processing carried out before you withdraw your consent. If you withdraw your consent, we may not be able to provide certain products or services to you. We will advise you if this is the case at the time you withdraw your consent.
              </li>
            </ul>
          </li>
          <li><span className="number--indent"></span> If you wish to exercise any of the rights set out above, please contact us.
          </li>
          <li><span className="number--indent">16.3</span> You will not usually have to pay a fee to access your Personal Data (or to exercise any of the other rights). However, we may charge a reasonable fee if your request is clearly unfounded, repetitive or excessive. Alternatively, we may refuse to comply with your request in these circumstances.
          </li>
          <li><span className="number--indent">16.4</span> We may need to request specific information from you to help us confirm your identity and ensure your right to access your Personal Data (or to exercise any of your other rights). This is a security measure to ensure that Personal Data is not disclosed to any person who has no right to receive it. We may also contact you to ask you for further information in relation to your request to speed up our response.
          </li>
        </ul>
        <h2><span className="head2--indent">17.</span> TIME LIMITS TO RESPOND</h2>
        <ul>
          <li><span className="number--indent"></span> We try to respond to all legitimate requests within one month of the time we receive all of the information required to complete your request. Occasionally it may take us longer than a month if your request is particularly complex or you have made a number of requests. In this case, we will notify you and keep you updated.
          </li>
          </ul>
        <h2><span className="head2--indent">18.</span> QUESTIONS AND CONTACT</h2>
        <ul>
          <li><span className="number--indent">18.1</span> If you have any questions about this Policy, including any requests to exercise your legal rights, please contact us using the details set out below.
          </li>
          <li><span className="number--indent">18.2</span> Our details are:
            <ul>
              <li><span className="number--indent"></span><u>Full name of legal entity</u>: The Infrastructure Deals Network Ltd (company number 12894864)</li>
              <li><span className="number--indent"></span><u>Postal address</u>: Kemp House, 160 City Road, London, United Kingdom, EC1V 2NX</li>
              <li><span className="number--indent"></span><u>Email</u>: <a className="site-link" href="mailto:info@idn.ltd">info@idn.ltd</a></li>
            </ul>
          </li>
          <li><span className="number--indent">18.3</span> If you wish to contact us regarding your rights, we reserve the right to request copies of your identification or any other information that may be reasonably required to verify your identity.
          </li>
        </ul>

        <h4 style={{ textAlign: 'center' }}>SCHEDULE: DEFINED TERMS</h4>
        <div className="definition">
          <div className="def-title">“Account”</div>
          <div className="def-text">means an account created by any user of the Services available on our Platform;</div>
        </div>
        <div className="definition">
          <div className="def-title">“Anonymous Data”</div>
          <div className="def-text">means Personal Data that has been amended to the extent that it no longer contains any identifying information and thus, no longer constitutes Personal Data;</div>
        </div>
        <div className="definition">
          <div className="def-title">“Comply with a Legal Obligation”</div>
          <div className="def-text">means processing your Personal Data where it is necessary for compliance with a legal obligation to which we are subject;</div>
        </div>
        <div className="definition">
          <div className="def-title">“Data Controller”</div>
          <div className="def-text">means the natural or legal person who (either alone or jointly or in common with other persons) determines the purposes for which and the way any Personal Data are, or are to be, processed as outlined in the Data Protection Legislation;</div>
        </div>
        <div className="definition">
          <div className="def-title">“Data Processor”</div>
          <div className="def-text">means any natural or legal person who processes the data on behalf of the Data Controller;</div>
        </div>
        <div className="definition">
          <div className="def-title">“Data Protection Legislation”</div>
          <div className="def-text">all applicable data protection and privacy legislation in force from time to time in the UK including the General Data Protection Regulation ((EU) 2016/679); the Data Protection Act 2018; the Privacy and Electronic Communications Directive 2002/58/EC (as updated by Directive 2009/136/EC) and the Privacy and Electronic Communications Regulations 2003 (SI 2003/2426) as amended;</div>
        </div>
        <div className="definition">
          <div className="def-title">“Legitimate Interest”</div>
          <div className="def-text">means our interest in conducting and managing our business to enable us to give you the best service and the best and most secure experience. We make sure we consider and balance any potential impact on you (both positive and negative) and your rights before we process your Personal Data for our legitimate interest. We do not use your Personal Data for activities where our interests are overridden by the impact on you (unless we have your consent or are otherwise required or permitted by law). You can obtain further information about how we assess our legitimate interest against any potential impact on you in respect of specific activities by contacting us;</div>
        </div>
        <div className="definition">
          <div className="def-title">“Personal Data”</div>
          <div className="def-text">has the meaning given to it in the Data Protection Legislation;</div>
        </div>
      </div>
      <MainFooter />
    </div>
  )
}

export default PrivacyPolicyPage
