import React, {useEffect} from 'react'
import {
  Router,
  Switch,
  Route
} from 'react-router-dom'
import { connect } from 'react-redux'
import { history } from './Redux'
import { Layout, message } from 'antd'

import errorHandler from './Utils/errorHandler'
import AuthActions from './Redux/AuthRedux'

import PrivateRoute from './Components/PrivateRoute'
import ScrollToTop from './Components/ScrollToTop'
import MainPage from './Containers/Landing/MainPage'
import CompaniesPage from './Containers/Landing/CompaniesPage'
import AdvisorsPage from './Containers/Landing/AdvisorsPage'
import IndustryPage from './Containers/Landing/IndustryPage'
import InvestorsPage from './Containers/Landing/InvestorsPage'
import SectorsPage from './Containers/Landing/SectorsPage'
import TrackRecordPage from './Containers/Landing/TrackRecordPage'
import AboutPage from './Containers/Landing/old/AboutPage'
import TermsOfUsePage from './Containers/Landing/old/TermsOfUsePage'
// import PrivacyPolicyPage from './Containers/Landing/PrivacyPolicyPage'
import PrivacyPolicyPage from './Containers/Landing/old/PrivacyPolicyPage'
import CookiesPolicyPage from './Containers/Landing/old/CookiesPolicyPage'
import HomePage from './Containers/HomePage'
import DealDashboardPage from './Containers/DealDashboardPage'
import StartupPage from './Containers/StartupPage'
import LoginPage from './Containers/LoginPage'
import SignUpCheckPage from './Containers/SignUpCheckPage'
import ConfirmEmailCheckPage from './Containers/ConfirmEmailCheckPage'
import InvestorLoginCheckPage from './Containers/InvestorLoginCheckPage'
import AdvisorSignUpPage from './Containers/AdvisorSignUpPage'
import InvestorSignUpPage from './Containers/InvestorSignUpPage'
import NotFoundPage from './Containers/NotFoundPage'
import ResetPasswordPage from './Containers/ResetPasswordPage'
import RestorePasswordPage from './Containers/RestorePasswordPage'
import LockedPage from './Containers/LockedPage'
import LogoutPage from './Containers/LogoutPage'
import InvestorInviteColleaguesPage from './Containers/InvestorInviteColleaguesPage'
import InvestorInviteColleaguesSuccessPage from './Containers/InvestorInviteColleaguesSuccessPage'
import InvestorVerificationPage from './Containers/InvestorVerificationPage'
import UpdatesAgreedPage from './Containers/UpdatesAgreedPage'
import ImportantUpdatePage from './Containers/ImportantUpdatePage'
import MandateDealSubmittedCheckPage from './Containers/MandateDealSubmittedCheckPage'
import LoginPageNew from './Containers/LoginPage/LoginPageNew'

function Routes(props) {
  const { startup, role, result, error } = props;

  useEffect(() => {
    if (result?.type === 'contactRequest') {
      message.success('Contact request successfully sent');
    }
  }, [result]);

  useEffect(() => {
    if (error) {
      errorHandler(error, props.resetError);
    }
  }, [error]);

  let isStartup = true;
  if (startup.success === false) {
    return (
      <Layout>
        <Route path="*" component={StartupPage} />
      </Layout>
    )
  } else {
    isStartup = true;
  }

  return (
    <Router history={history}>
      <ScrollToTop>
        <Layout className="main-layout">
          <Switch>
            {/* <Route exact path="/" component={StartupPage}/> */}
            <Route exact path="/" component={MainPage}/>
            <Route exact path="/companies" component={CompaniesPage}/>
            <Route exact path="/advisors" component={AdvisorsPage}/>
            <Route exact path="/industry-executives" component={IndustryPage}/>
            <Route exact path="/investors" component={InvestorsPage}/>
            <Route exact path="/sectors" component={SectorsPage}/>
            <Route exact path="/track-record" component={TrackRecordPage}/>
            <Route exact path="/terms-of-use" component={TermsOfUsePage}/>
            <Route exact path="/privacy-policy" component={PrivacyPolicyPage}/>
            <Route exact path="/cookies-policy" component={CookiesPolicyPage}/>
            {/* <Route exact path="/about" component={AboutPage}/> */}
            {/* <Route exact path="/login" component={LoginPage}/> */}
            <Route exact path="/login" component={LoginPageNew}/>
            <Route path="/sign-up/:role?/check/:hash" component={SignUpCheckPage}/>
            <Route path="/confirm-email/:role?/check/:hash" component={ConfirmEmailCheckPage}/>
            <Route path="/login/check/:token" component={InvestorLoginCheckPage}/>
            <Route path="/advisor/sign-up/step/:step" component={AdvisorSignUpPage}/>
            <Route path="/investor/sign-up/step/:step" component={InvestorSignUpPage}/>
            <Route exact path="/reset-password" component={ResetPasswordPage}/>
            <Route exact path="/restore-password/:hash" component={RestorePasswordPage}/>
            <Route exact path="/locked" component={LockedPage}/>
            <Route exact path="/logout" component={LogoutPage}/>
            <PrivateRoute exact path="/invite-colleagues" isAllowed={role===2} component={InvestorInviteColleaguesPage}/>
            <PrivateRoute exact path="/invite-colleagues/success" isAllowed={role===2} component={InvestorInviteColleaguesSuccessPage}/>
            <Route exact path="/investor-verification" component={InvestorVerificationPage}/>
            <Route path="/important-update" component={ImportantUpdatePage} />
            <Route path="/updates-agreed" component={UpdatesAgreedPage} />
            <Route path="/mandate-deal-submitted/check/:hash" component={MandateDealSubmittedCheckPage} />

            {isStartup ? <Route path="/home/*" component={HomePage} /> : null }
            {isStartup ? <Route path="/deal-dashboard/*" component={DealDashboardPage} /> : null }
            <Route path="/*" component={NotFoundPage} />
          </Switch>
        </Layout>
      </ScrollToTop>
    </Router>
  )
}

const mapStateToProps = (state) => {
  return {
    startup: state.startup,
    role: state.auth.role,
    result: state.auth.result,
    error: state.auth.error,
    // subRole: state.auth.investorSubRole,
  }
}

const mapDispatchToProps = (dispatch) => ({
  resetError: () => dispatch(AuthActions.authErrorReset()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Routes)
