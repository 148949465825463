import React, { useEffect, useState } from 'react';
import classname from 'classname';
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Images from '../../../Images';
import { Typography } from 'antd'

import "./MainPageCarousel.less";

const {Title, Text} = Typography

const images = [
  { id: 1, src: Images.lazard, alt: "Lazard" },
  { id: 2, src: Images.vitol, alt: "Vitol" },
  { id: 3, src: Images.goldman, alt: "Goldman Sachs" },
  { id: 4, src: Images.dws, alt: "DWS" },
  { id: 5, src: Images.squared, alt: "Squared" },
  // { id: 6, src: Images.vitol, alt: "Vitol" },
];


// const responsive = [
//   {
//     breakpoint: 1130,
//     settings: {
//       slidesToShow: 2,
//     }
//   },
//   {
//     breakpoint: 768,
//     settings: {
//       slidesToShow: 1,
//     }
//   },
// ]

const categoryItemStyle = {
  // display: 'flex',
  // alignItems: 'center',
  // gap: '10px',
  // background: '#fff',
  // padding: '15px 14px',
  // borderRadius: '8px',
  // cursor: 'pointer',
  // boxShadow: (isMobile && isHomePage) || (is850 && !isHomePage )  ? '0px 0px 1px #0000001A' : '0px 10px 12px 0px #6363660D, 0px 1px 3px 0px #0000000D',
};

const MainPageCarousel = () => {

  const [currentIndex, setCurrentIndex] = useState(0);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 576);

  // const settings = {
  //   infinite: true,
  //   slidesToShow: 5,
  //   slidesToScroll: 1,
  //   autoplay: true,
  //   autoplaySpeed: 3000,
  //   centerMode: false,
  //   adaptiveHeight: true,
  //   beforeChange: (oldIndex, newIndex) => setCurrentIndex(newIndex),
  // };

  const settings = {
    // dots: isMobile ? true : false,
    dots: false,
    infinite: true,
    speed: 2000,
    slidesToShow: isSmallScreen ? 3 : 5,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    useTransform: false,
    beforeChange: (oldIndex, newIndex) => setCurrentIndex(newIndex),
  };

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 576);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const diffIndex = isSmallScreen ? 1 : 2;

  return (
    <div className='main-slider-wrapper' >
      <Slider {...settings}
        // responsive={responsive}
      >
        {images?.map((el, index )=> {

          const totalSlides = images.length;
          const adjustedIndex = currentIndex % totalSlides;

          const positionDiff = (index - adjustedIndex + totalSlides) % totalSlides;

          const opacity =
            positionDiff === diffIndex // central element
              ? 1
              : positionDiff === 1 || positionDiff === 3
              ? 0.4
              : 0.2;

            return (
                <div className='firms-carousel-item-wrapper' key={el.id}>
                  <div className={`carousel-item`}>
                    <img src={el.src} alt={el.alt}
                      style={{
                        opacity: opacity,
                        transition: "opacity 1s ease-in-out",
                        // width: 78,
                      }}
                    />
                  </div>
                </div>
              )
          })}
      </Slider>
    </div>
  );
};

export default MainPageCarousel;


  // const checkWidth = (width) => {
  //   const correctWidth = width;
  //   setIs850(correctWidth <= 850);
  // };

  // useEffect(() => {
  //   checkWidth(window.innerWidth);
  //   const handleResize = () => {
  //     checkWidth(window.innerWidth);
  //   };
  //   window.addEventListener('resize', handleResize);
  //   return () => window.removeEventListener('resize', handleResize);
  // }, []);