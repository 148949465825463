import React from 'react';
import {Link} from 'react-router-dom'
import {Dropdown, Menu, Divider} from 'antd'

import { ReactComponent as MenuIcon} from '../../../Images/landing_new/menu.svg';
import './MenuComponent.less'

const MenuComponent = ({setIsModalOpen}) => {

  const menu = (
    <Menu>
      <Link to="/companies">
        <Menu.Item>Companies</Menu.Item>
      </Link>
      <Link to="/advisors">
        <Menu.Item>M&A Advisors</Menu.Item>
      </Link>
      <Link  to="/industry-executives">
        <Menu.Item>Industry Executives</Menu.Item>
      </Link>
      <Link  to="/investors">
        <Menu.Item>Investors</Menu.Item>
      </Link>
      <Link  to="/track-record">
        <Menu.Item>Track Record</Menu.Item>
      </Link>
      {/* <Link  to="/sectors">
        <Menu.Item>Sectors</Menu.Item>
      </Link> */}
      {/* <Divider style={{margin: '4px 0'}} /> */}
      <div onClick={()=> setIsModalOpen(true)}>
        <Menu.Item className='align-end'>Contact us</Menu.Item>
      </div>
      <Link  to="/login">
        <Menu.Item className='align-end'>Login</Menu.Item>
      </Link>
    </Menu>
  );

  return (
    <div className="dropdown-wrap">
      <Dropdown
        overlay={menu}
        overlayClassName={'custom-menu'}
        trigger={['click']}
        placement="bottomLeft"
      >
        <MenuIcon className='header-menu-icon'/>
      </Dropdown>
    </div>
  )
}

export default MenuComponent;