import { takeLatest, all, fork } from 'redux-saga/effects'
import AuthAPI from '../Services/AuthApi'
import API from '../Services/Api'
import { retry, linearGrowth } from 'redux-saga-retry';

/* ------------- Types ------------- */

import { StartupTypes } from '../Redux/StartupRedux'

import { AuthTypes } from '../Redux/AuthRedux'

import { SettingsTypes } from '../Redux/SettingsRedux'

import { ExperienceTypes } from '../Redux/ExperienceRedux'

import { UsersTypes } from '../Redux/UsersRedux'

import { FirmsTypes } from '../Redux/FirmsRedux'

import { MailTemplatesTypes } from '../Redux/MailTemplatesRedux'

import { DealsTypes } from '../Redux/DealsRedux'

import { DealSharesTypes } from '../Redux/DealSharesRedux'

import { DealDashboardTypes } from '../Redux/DealDashboardRedux'

import { ChatTypes } from '../Redux/ChatRedux'

import { NotificationsTypes } from '../Redux/NotificationsRedux'

import { ExpertConsultTypes } from '../Redux/ExpertConsultRedux'

import { MandatesTypes } from '../Redux/MandatesRedux'

import { MandateSharesTypes } from '../Redux/MandateSharesRedux'

/* ------------- Sagas ------------- */

import { startup } from './StartupSagas'

import {
  signIn,
  checkEmailExist,
  checkInviteHash,
  checkConfirmEmailHash,
  signUp,
  confirmEmail,
  unlockAccount,
  resetPassword,
  restorePassword,
  sendIntroData,
  sendApplicationForm,
  sendFirmInvite,
  investorLogin,
  getInviteData,
  sendUserInvite,
  saveAdvisorFilters,
  saveFirmFilters,
  getAgreementLink,
  checkMandateDealHash,
  sendContactForm,
} from './AuthSagas'

import {
  getFilters,
  updateFilters,
  getFirmFilters,
  updateFirmFilters,
  updateBilateralContract,
  addBilateralContractByAdmin,
  getSectorFocus,
  createSectorItem,
  updateSectorItem,
  deleteSectorItem,
  changeSectorOrder,
  getGeographyFocus,
  getEquityGovernance,
  getInvestmentTypes,
  getCountries,
  getEuCountries,
  getRegions,
  getInvestorFilters,
  getUpdateAgreement,
  confirmAgreementByAdvisor,
  getAgreementBlank,
  getExportData,
  resetTableSettings
} from './SettingsSagas'

import {
  getExperience,
  deleteExperience,
  saveExperience,
  getExperienceJobs,
  deleteExperienceJob,
  saveExperienceJob
} from './ExperienceSagas'

import {
  getUsers,
  createUser,
  deleteUser,
  getInvestorRoles,
  getInvestorColleagues,
  getUserLockHistory,
  toggleUserLock,
  getUserChoices,
  changeUserPassword
} from './UsersSagas'

import {
  getFirms,
  getFirm,
  saveFirm,
  deleteFirm,
} from './FirmsSagas'

import {
  getMailTemplates,
  getMailTemplate,
  updateMailTemplate,
} from './MailTemplatesSagas'

import {
  getDeals,
  getDeal,
  createDeal,
  deleteDeal,
  changeDealStatus,
  deleteDealNotification,
  deleteDealNotification_,
  saveDealOverview,
  saveDealRoleInfo,
  saveDealInvestorSought,
  saveDealGroupData,
  withdrawDeal,
  getDealStatuses,
  getInvestorShareStatuses,
  getDealInvestorsShared,
  getDealAdvisorShared,
  shareDealWithSpecificInvestor,
  getDealAdvisorSharedHistory,
  shareDealWithInvestors,
  reshareDealRequestByAdvisor,
  reshareDealByAdmin,
  reshareDealByAdvisor,
  shareDealByAdvisor,
  applyDealByInvestor_,
  shareDealWithColleagues_,
  getDealTargetStatus,
  saveDealTargetStatus,
  updateDealTargetStatus,
  rejectMandateDeal,
  shareMandateDealByAdmin,
  changeNotificationDealStatus,
  dealsSyncStartMonitor,
} from './DealsSagas'

import {
  getDealShares,
  getDealMatchInfo,
  changeDealShareStatus,
  deleteDealSharesNotification,
  updateDealShareData,
  applyDealByInvestor,
  reapplyDealByInvestor,
  shareDealWithColleagues,
  getInvestorStats,
  getFirmDeals,
  getFirmDeal,
  getFirmStats,
  withdrawApplication,
  rejectDealByInvestor,
  createMatchAnswer,
  updateMatchAnswer,
  keepMatchUnderReview,
  updateSeenByInvestor,
  confirmMatchByAdvisor,
  checkConfirmMatchHash,
  dealSharesSyncStartMonitor
} from './DealSharesSagas'

import {
  getDealDashboardInfo,
  updateSeenByAdvisor,
  saveTargetCompany,
  getTargetCompany,
  editTargetCompany,
  inviteColleaguesToDashboard,
  terminateDealByInvestor,
  terminateDealByAdvisor,
  proceedAdvisorParties,
  proceedInvestorParties,
  requestIntroByInvestor,
  occuredIntroByAdvisor,
  sendUltimatumByAdvisor,
  acceptUltimatumByInvestor,
  rejectUltimatumByInvestor,
  submittedNBOByInvestor,
  acceptedNBOByInvestor,
  acceptedBindingOfferByInvestor,
  closeSuccessByInvestor,
  changeDealToPublicByInvestor,
  changeNotificationStatus,
  revertActionByAdmin,
  viewDealClosedAdvisor,
  viewDealClosedInvestor,
  uploadBilateralByAdvisor,
  dealDashboardSyncStartMonitor,
} from './DealDashboardSagas'

import {
  getChatMembers,
  removeChatNotification,
  getChatMessages,
  sendChatMessage,
  setChatUserStatus
} from './ChatSagas'

import {
  getNotifications,
  getInvestorDealGroupNotifications,
  getAdvisorDealGroupNotifications,
  getDealDashboardNotifications,
  getInvestorMandateGroupNotifications,
  getAdvisorMandateGroupNotifications,
  notificationsSyncStartMonitor,
  dealDashboardNotificationsSyncStartMonitor
} from './NotificationsSagas'

import {
  getExpertConsultingInfo,
  saveAgreementDataByInvestor,
  triggerActionEngageAdvisor,
  triggerActionInvestorEditAgreement,
  triggerActionAdvisorSignAgreement,
  hoursUploadRequest,
  getHoursRequest,
  hoursUpDateRequest,
  investorImproveHoursRequest,
  investorExtendAgreementRequest,
  investorEditExtensionRequest,
  advisorSignExtensionRequest,
  getAgreementRequest,
  getDataForAgreementRequest,
  agreementDataUpdateRequest,
  notificationViewedRequest,
  expertConsultActionHistoryRequest,
  uploadAgreementByInvestor,
  reuploadAgreementByInvestor,
  uploadAgreementByAdvisor,
  advisorEnterOptionsRequest,
  getWorkDoneData,
  editDataByAdmin,
  expertConsultSyncStartMonitor,
} from './ExpertConsultSagas'

import {
  getMandates,
  getDealsSubmitted,
  getMandate,
  createMandate,
  deleteMandateNotification,
  getDealSize,
  saveMandateGroupData,
  sharedMandateByInvestor,
  pauseMandate,
  unpauseMandate,
  closeMandate,
  mandateAskQuestionRequest,
  answerMandateQuestion,
  inviteColleaguesToMandate,
  getMandateStatuses,
  getAdvisorShareStatuses,
  getMandateAdvisorsShared,
  shareMandateWithAdvisors,
  getMandateDeals,
  getMandateStats,
  changeNotificationMandateStatus,
  addMandateByAdvisor,
  getFirmMandates,
  getFirmMandate,
  getFirmActivitiesMandateStats,
  getFirmMandateStats,
  getFirmMandateDeals,
  editMandateQuestion,
  deleteMandateQuestion,
  publishMandateQuestion,
  mandatesSyncStartMonitor
} from './MandatesSagas'

import {
  getMandateShares,
  getMandateMatchInfo,
  mandateSharesDeleteRequest,
  deleteMandateSharesNotification,
  mandateSharesSyncStartMonitor
} from './MandateSharesSagas'

/* ------------- API ------------- */

// The API we use is only used from Sagas, so we create it here and pass along
// to the sagas which need it.
const authApi = AuthAPI;
const api = API;
//const usersApi = UsersAPI;

/* ------------- Connect Types To Sagas ------------- */

const retryOptions = {
  retries: 2,
  backoff: linearGrowth,
};

export default function * root () {
  yield all([
    // some sagas only receive an action
    takeLatest(StartupTypes.STARTUP, startup),

    takeLatest(AuthTypes.SIGN_IN_REQUEST, signIn, authApi),
    takeLatest(AuthTypes.CHECK_EMAIL_EXIST_REQUEST, checkEmailExist, authApi),
    takeLatest(AuthTypes.INVITE_HASH_REQUEST, checkInviteHash, authApi),
    takeLatest(AuthTypes.CONFIRM_EMAIL_HASH_REQUEST, checkConfirmEmailHash, authApi),
    takeLatest(AuthTypes.SIGN_UP_REQUEST, retry(signUp, retryOptions), authApi),
    takeLatest(AuthTypes.CONFIRM_EMAIL_REQUEST, confirmEmail, authApi),
    takeLatest(AuthTypes.UNLOCK_REQUEST, retry(unlockAccount, retryOptions), authApi),
    takeLatest(AuthTypes.RESET_PASSWORD_REQUEST, resetPassword, authApi),
    takeLatest(AuthTypes.RESTORE_PASSWORD_REQUEST, restorePassword, authApi),
    takeLatest(AuthTypes.INVESTOR_INTRO_REQUEST, retry(sendIntroData, retryOptions), authApi),
    takeLatest(AuthTypes.ADVISOR_INTRO_REQUEST, retry(sendApplicationForm, retryOptions), authApi),
    takeLatest(AuthTypes.SEND_FIRM_INVITE_REQUEST, retry(sendFirmInvite, retryOptions), authApi),
    takeLatest(AuthTypes.INVESTOR_LOGIN_REQUEST, investorLogin, authApi),
    takeLatest(AuthTypes.GET_INVITE_DATA_REQUEST, getInviteData, authApi),
    takeLatest(AuthTypes.SEND_INVITE_REQUEST, sendUserInvite, authApi),
    takeLatest(AuthTypes.ADVISOR_FILTERS_REQUEST, retry(saveAdvisorFilters, retryOptions), authApi),
    takeLatest(AuthTypes.FIRM_PRE_FILTERS_REQUEST, retry(saveFirmFilters, retryOptions), authApi),
    takeLatest(AuthTypes.ADVISOR_AGREEMENT_LINK_REQUEST, retry(getAgreementLink, retryOptions), authApi),
    takeLatest(AuthTypes.MANDATE_DEAL_SUBMITTED_CHECK_REQUEST, checkMandateDealHash, authApi),
    takeLatest(AuthTypes.CONTACT_REQUEST, sendContactForm, authApi),

    takeLatest(SettingsTypes.FILTERS_REQUEST, getFilters, api, authApi),
    takeLatest(SettingsTypes.FILTERS_SAVE_REQUEST, updateFilters, api, authApi),
    takeLatest(SettingsTypes.FIRM_FILTERS_REQUEST, getFirmFilters, api, authApi),
    takeLatest(SettingsTypes.FIRM_FILTERS_SAVE_REQUEST, updateFirmFilters, api, authApi),
    takeLatest(SettingsTypes.BILATERAL_CONTRACT_SAVE_REQUEST, updateBilateralContract, api, authApi),
    takeLatest(SettingsTypes.ADD_BILATERAL_CONTRACT_BY_ADMIN_REQUEST, addBilateralContractByAdmin, api, authApi),
    takeLatest(SettingsTypes.SECTOR_FOCUS_REQUEST, getSectorFocus, api, authApi),
    takeLatest(SettingsTypes.SECTOR_FOCUS_ITEM_CREATE_REQUEST, createSectorItem, api, authApi),
    takeLatest(SettingsTypes.SECTOR_FOCUS_ITEM_UPDATE_REQUEST, updateSectorItem, api, authApi),
    takeLatest(SettingsTypes.SECTOR_FOCUS_ITEM_DELETE_REQUEST, deleteSectorItem, api, authApi),
    takeLatest(SettingsTypes.SECTOR_FOCUS_ORDER_REQUEST, changeSectorOrder, api, authApi),
    takeLatest(SettingsTypes.GEOGRAPHY_FOCUS_REQUEST, getGeographyFocus, api, authApi),
    takeLatest(SettingsTypes.EQUITY_GOVERNANCE_REQUEST, getEquityGovernance, api, authApi),
    takeLatest(SettingsTypes.INVESTMENT_TYPES_REQUEST, getInvestmentTypes, api, authApi),
    takeLatest(SettingsTypes.COUNTRIES_REQUEST, getCountries, api, authApi),
    takeLatest(SettingsTypes.EU_COUNTRIES_REQUEST, getEuCountries, api, authApi),
    takeLatest(SettingsTypes.REGIONS_REQUEST, getRegions, api, authApi),
    takeLatest(SettingsTypes.INVESTOR_FIRM_FILTERS_REQUEST, getInvestorFilters, api, authApi),
    takeLatest(SettingsTypes.ADVISOR_UPDATE_AGREEMENT_REQUEST, getUpdateAgreement, api, authApi),
    takeLatest(SettingsTypes.ADVISOR_CONFIRM_AGREEMENT_REQUEST, confirmAgreementByAdvisor, api, authApi),
    takeLatest(SettingsTypes.ADVISOR_AGREEMENT_BLANK_REQUEST, getAgreementBlank, api, authApi),
    takeLatest(SettingsTypes.EXPORT_DATA_REQUEST, getExportData, api, authApi),
    takeLatest(SettingsTypes.TABLE_SETTINGS_RESET, resetTableSettings),

    takeLatest(ExperienceTypes.EXPERIENCE_REQUEST, getExperience, api, authApi),
    takeLatest(ExperienceTypes.EXPERIENCE_DELETE_REQUEST, deleteExperience, api, authApi),
    takeLatest(ExperienceTypes.EXPERIENCE_SAVE_REQUEST, saveExperience, api, authApi),
    takeLatest(ExperienceTypes.EXPERIENCE_JOBS_REQUEST, getExperienceJobs, api, authApi),
    takeLatest(ExperienceTypes.EXPERIENCE_JOB_DELETE_REQUEST, deleteExperienceJob, api, authApi),
    takeLatest(ExperienceTypes.EXPERIENCE_JOB_SAVE_REQUEST, saveExperienceJob, api, authApi),

    takeLatest(UsersTypes.USERS_REQUEST, getUsers, api, authApi),
    takeLatest(UsersTypes.USER_SAVE_REQUEST, createUser, api, authApi),
    takeLatest(UsersTypes.USER_DELETE_REQUEST, deleteUser, api, authApi),
    takeLatest(UsersTypes.INVESTOR_ROLES_REQUEST, getInvestorRoles, api, authApi),
    takeLatest(UsersTypes.INVESTOR_COLLEAGUES_REQUEST, getInvestorColleagues, api, authApi),
    takeLatest(UsersTypes.USER_LOCK_HISTORY_REQUEST, getUserLockHistory, api, authApi),
    takeLatest(UsersTypes.USER_TOGGLE_LOCK_REQUEST, toggleUserLock, api, authApi),
    takeLatest(UsersTypes.USER_CHOICES_REQUEST, getUserChoices, api, authApi),
    takeLatest(UsersTypes.USER_CHANGE_PASSWORD_REQUEST, changeUserPassword, api, authApi),

    takeLatest(FirmsTypes.FIRMS_REQUEST, getFirms, api, authApi),
    takeLatest(FirmsTypes.FIRM_REQUEST, getFirm, api, authApi),
    takeLatest(FirmsTypes.FIRM_SAVE_REQUEST, saveFirm, api, authApi),
    takeLatest(FirmsTypes.FIRM_DELETE_REQUEST, deleteFirm, api, authApi),

    takeLatest(MailTemplatesTypes.MAIL_TEMPLATES_REQUEST, getMailTemplates, api, authApi),
    takeLatest(MailTemplatesTypes.MAIL_TEMPLATE_REQUEST, getMailTemplate, api, authApi),
    takeLatest(MailTemplatesTypes.MAIL_TEMPLATE_SAVE_REQUEST, updateMailTemplate, api, authApi),

    takeLatest(DealsTypes.DEALS_REQUEST, getDeals, api, authApi),
    takeLatest(DealsTypes.DEAL_REQUEST, getDeal, api, authApi),
    takeLatest(DealsTypes.DEAL_SAVE_REQUEST, createDeal, api, authApi),
    takeLatest(DealsTypes.DEAL_DELETE_REQUEST, deleteDeal, api, authApi),
    takeLatest(DealsTypes.DEAL_CHANGE_STATUS_REQUEST, changeDealStatus, api, authApi),
    takeLatest(DealsTypes.DEAL_NOTIFICATION_DELETE_REQUEST, deleteDealNotification, api, authApi),
    takeLatest(DealsTypes.DEAL_NOTIFICATION_DELETE_REQUEST_, deleteDealNotification_, api, authApi),
    takeLatest(DealsTypes.DEAL_OVERVIEW_SAVE_REQUEST, saveDealOverview, api, authApi),
    takeLatest(DealsTypes.DEAL_ROLE_INFO_SAVE_REQUEST, saveDealRoleInfo, api, authApi),
    takeLatest(DealsTypes.DEAL_INVESTOR_SOUGHT_SAVE_REQUEST, saveDealInvestorSought, api, authApi),
    takeLatest(DealsTypes.DEAL_GROUP_DATA_SAVE_REQUEST, saveDealGroupData, api, authApi),
    takeLatest(DealsTypes.DEAL_WITHDRAW_REQUEST, withdrawDeal, api, authApi),
    takeLatest(DealsTypes.DEAL_STATUSES_REQUEST, getDealStatuses, api, authApi),
    takeLatest(DealsTypes.DEAL_INVESTOR_SHARE_STATUSES_REQUEST, getInvestorShareStatuses, api, authApi),
    takeLatest(DealsTypes.DEAL_INVESTORS_SHARED_REQUEST, getDealInvestorsShared, api, authApi),
    takeLatest(DealsTypes.DEAL_ADVISORS_SHARED_REQUEST, getDealAdvisorShared, api, authApi),
    takeLatest(DealsTypes.DEAL_SHARE_WITH_SPECIFIC_INVESTOR_REQUEST, shareDealWithSpecificInvestor, api, authApi),
    takeLatest(DealsTypes.DEAL_ADVISORS_SHARED_HISTORY_REQUEST, getDealAdvisorSharedHistory, api, authApi),
    takeLatest(DealsTypes.DEAL_SHARE_WITH_INVESTORS_REQUEST, shareDealWithInvestors, api, authApi),
    takeLatest(DealsTypes.DEAL_RESHARE_REQUEST_BY_ADVISOR_REQUEST, reshareDealRequestByAdvisor, api, authApi),
    takeLatest(DealsTypes.DEAL_RESHARE_BY_ADMIN_REQUEST, reshareDealByAdmin, api, authApi),
    takeLatest(DealsTypes.DEAL_RESHARE_BY_ADVISOR_REQUEST, reshareDealByAdvisor, api, authApi),
    takeLatest(DealsTypes.DEAL_SHARE_BY_ADVISOR_REQUEST, shareDealByAdvisor, api, authApi),
    takeLatest(DealsTypes.DEAL_INVESTOR_APPLY_REQUEST_, applyDealByInvestor_, api, authApi),
    takeLatest(DealsTypes.DEAL_INVESTOR_SEND_COLLEAGUES_REQUEST_, shareDealWithColleagues_, api, authApi),
    takeLatest(DealsTypes.DEAL_TARGET_STATUS_REQUEST, getDealTargetStatus, api, authApi),
    takeLatest(DealsTypes.DEAL_TARGET_STATUS_SAVE_REQUEST, saveDealTargetStatus, api, authApi),
    takeLatest(DealsTypes.DEAL_TARGET_STATUS_UPDATE_REQUEST, updateDealTargetStatus, api, authApi),
    takeLatest(DealsTypes.MANDATE_DEAL_REJECT_REQUEST, rejectMandateDeal, api, authApi),
    takeLatest(DealsTypes.ADMIN_SHARE_MANDATE_DEAL_REQUEST, shareMandateDealByAdmin, api, authApi),
    takeLatest(DealsTypes.NOTIFICATION_CHANGE_DEAL_STATUS_REQUEST, changeNotificationDealStatus, api, authApi),
    fork(dealsSyncStartMonitor),

    takeLatest(DealSharesTypes.DEAL_SHARES_REQUEST, getDealShares, api, authApi),
    takeLatest(DealSharesTypes.DEAL_SHARE_MATCH_REQUEST, getDealMatchInfo, api, authApi),
    takeLatest(DealSharesTypes.DEAL_SHARE_CHANGE_STATUS_REQUEST, changeDealShareStatus, api, authApi),
    takeLatest(DealSharesTypes.DEAL_SHARES_NOTIFICATION_DELETE_REQUEST, deleteDealSharesNotification, api, authApi),
    takeLatest(DealSharesTypes.DEAL_SHARE_UPDATE_REQUEST, updateDealShareData, api, authApi),
    takeLatest(DealSharesTypes.DEAL_INVESTOR_APPLY_REQUEST, applyDealByInvestor, api, authApi),
    takeLatest(DealSharesTypes.DEAL_INVESTOR_REAPPLY_REQUEST, reapplyDealByInvestor, api, authApi),
    takeLatest(DealSharesTypes.DEAL_INVESTOR_SEND_COLLEAGUES_REQUEST, shareDealWithColleagues, api, authApi),
    takeLatest(DealSharesTypes.DEAL_SHARES_INVESTOR_STATS_REQUEST, getInvestorStats, api, authApi),
    takeLatest(DealSharesTypes.FIRM_DEALS_REQUEST, getFirmDeals, api, authApi),
    takeLatest(DealSharesTypes.FIRM_DEAL_REQUEST, getFirmDeal, api, authApi),
    takeLatest(DealSharesTypes.FIRM_STATS_REQUEST, getFirmStats, api, authApi),
    takeLatest(DealSharesTypes.APPLICATION_WITHDRAW_REQUEST, withdrawApplication, api, authApi),
    takeLatest(DealSharesTypes.DEAL_INVESTOR_REJECT_REQUEST, rejectDealByInvestor, api, authApi),
    takeLatest(DealSharesTypes.MATCH_ANSWER_SAVE_REQUEST, createMatchAnswer, api, authApi),
    takeLatest(DealSharesTypes.MATCH_ANSWER_UPDATE_REQUEST, updateMatchAnswer, api, authApi),
    takeLatest(DealSharesTypes.ADVISOR_KEEP_MATCH_REVIEW_REQUEST, keepMatchUnderReview, api, authApi),
    takeLatest(DealSharesTypes.SEEN_BY_INVESTOR_REQUEST, updateSeenByInvestor, api, authApi),
    takeLatest(DealSharesTypes.ADVISOR_MATCH_CONFIRM_REQUEST, confirmMatchByAdvisor, api, authApi),
    takeLatest(DealSharesTypes.MATCH_CONFIRM_HASH_REQUEST, checkConfirmMatchHash, api, authApi),
    fork(dealSharesSyncStartMonitor),


    takeLatest(DealDashboardTypes.DEAL_DASHBOARD_REQUEST, getDealDashboardInfo, api, authApi),
    takeLatest(DealDashboardTypes.SEEN_BY_ADVISOR_REQUEST, updateSeenByAdvisor, api, authApi),
    takeLatest(DealDashboardTypes.TARGET_COMPANY_SAVE_REQUEST, saveTargetCompany, api, authApi),
    takeLatest(DealDashboardTypes.TARGET_COMPANY_REQUEST, getTargetCompany, api, authApi),
    takeLatest(DealDashboardTypes.TARGET_COMPANY_EDIT_REQUEST, editTargetCompany, api, authApi),
    takeLatest(DealDashboardTypes.INVITE_DASHBOARD_REQUEST, inviteColleaguesToDashboard, api, authApi),
    takeLatest(DealDashboardTypes.INVESTOR_TERMINATE_DEAL_REQUEST, terminateDealByInvestor, api, authApi),
    takeLatest(DealDashboardTypes.ADVISOR_TERMINATE_DEAL_REQUEST, terminateDealByAdvisor, api, authApi),
    takeLatest(DealDashboardTypes.ADVISOR_PROCEED_PARTIES_REQUEST, proceedAdvisorParties, api, authApi),
    takeLatest(DealDashboardTypes.INVESTOR_PROCEED_PARTIES_REQUEST, proceedInvestorParties, api, authApi),
    takeLatest(DealDashboardTypes.INVESTOR_REQUEST_INTRO_REQUEST, requestIntroByInvestor, api, authApi),
    takeLatest(DealDashboardTypes.ADVISOR_OCCURED_INTRO_REQUEST, occuredIntroByAdvisor, api, authApi),
    takeLatest(DealDashboardTypes.ADVISOR_SEND_ULTIMATUM_REQUEST, sendUltimatumByAdvisor, api, authApi),
    takeLatest(DealDashboardTypes.INVESTOR_ACCEPT_ULTIMATUM_REQUEST, acceptUltimatumByInvestor, api, authApi),
    takeLatest(DealDashboardTypes.INVESTOR_REJECT_ULTIMATUM_REQUEST, rejectUltimatumByInvestor, api, authApi),
    takeLatest(DealDashboardTypes.INVESTOR_SUBMITTED_NBO_REQUEST, submittedNBOByInvestor, api, authApi),
    takeLatest(DealDashboardTypes.INVESTOR_ACCEPTED_NBO_REQUEST, acceptedNBOByInvestor, api, authApi),
    takeLatest(DealDashboardTypes.INVESTOR_ACCEPTED_BINDING_OFFER_REQUEST, acceptedBindingOfferByInvestor, api, authApi),
    takeLatest(DealDashboardTypes.INVESTOR_SUCCESS_CLOSE_REQUEST, closeSuccessByInvestor, api, authApi),
    takeLatest(DealDashboardTypes.DEAL_CHANGE_PUBLIC_REQUEST, changeDealToPublicByInvestor, api, authApi),
    takeLatest(DealDashboardTypes.NOTIFICATION_CHANGE_STATUS_REQUEST, changeNotificationStatus, api, authApi),
    takeLatest(DealDashboardTypes.ADMIN_REVERT_ACTION_REQUEST, revertActionByAdmin, api, authApi),
    takeLatest(DealDashboardTypes.ADVISOR_SEEN_CLOSED_DEAL_REQUEST, viewDealClosedAdvisor, api, authApi),
    takeLatest(DealDashboardTypes.INVESTOR_SEEN_CLOSED_DEAL_REQUEST, viewDealClosedInvestor, api, authApi),
    takeLatest(DealDashboardTypes.ADVISOR_UPLOAD_BILATERAL_REQUEST, uploadBilateralByAdvisor, api, authApi),
    fork(dealDashboardSyncStartMonitor),

    takeLatest(ChatTypes.CHAT_MEMBERS_REQUEST, getChatMembers, api, authApi),
    takeLatest(ChatTypes.CHAT_REMOVE_NOTIFICATION_REQUEST, removeChatNotification, api, authApi),
    takeLatest(ChatTypes.CHAT_MESSAGES_REQUEST, getChatMessages, api, authApi),
    takeLatest(ChatTypes.CHAT_MESSAGE_SAVE_REQUEST, sendChatMessage, api, authApi),
    takeLatest(ChatTypes.CHAT_USER_STATUS_REQUEST, setChatUserStatus, api, authApi),

    takeLatest(NotificationsTypes.NOTIFICATIONS_REQUEST, getNotifications, api, authApi),
    takeLatest(NotificationsTypes.INVESTOR_DEAL_GROUP_NOTIFICATIONS_REQUEST, getInvestorDealGroupNotifications, api, authApi),
    takeLatest(NotificationsTypes.ADVISOR_DEAL_GROUP_NOTIFICATIONS_REQUEST, getAdvisorDealGroupNotifications, api, authApi),
    takeLatest(NotificationsTypes.DEAL_DASHBOARD_NOTIFICATIONS_REQUEST, getDealDashboardNotifications, api, authApi),
    takeLatest(NotificationsTypes.INVESTOR_MANDATE_GROUP_NOTIFICATIONS_REQUEST, getInvestorMandateGroupNotifications, api, authApi),
    takeLatest(NotificationsTypes.ADVISOR_MANDATE_GROUP_NOTIFICATIONS_REQUEST, getAdvisorMandateGroupNotifications, api, authApi),
    fork(notificationsSyncStartMonitor),
    fork(dealDashboardNotificationsSyncStartMonitor),

    takeLatest(ExpertConsultTypes.EXPERT_CONSULTING_REQUEST, getExpertConsultingInfo, api, authApi),
    takeLatest(ExpertConsultTypes.AGREEMENT_DATA_SAVE_REQUEST, saveAgreementDataByInvestor, api, authApi),
    takeLatest(ExpertConsultTypes.INVESTOR_ANGAGE_ADVISOR_REQUEST, triggerActionEngageAdvisor, api, authApi),
    takeLatest(ExpertConsultTypes.INVESTOR_EDIT_AGREEMENT_REQUEST, triggerActionInvestorEditAgreement, api, authApi),
    takeLatest(ExpertConsultTypes.ADVISOR_SIGN_AGREEMENT_REQUEST, triggerActionAdvisorSignAgreement, api, authApi),
    takeLatest(ExpertConsultTypes.HOURS_UPLOAD_REQUEST, hoursUploadRequest, api, authApi),
    takeLatest(ExpertConsultTypes.GET_HOURS_REQUEST, getHoursRequest, api, authApi),
    takeLatest(ExpertConsultTypes.HOURS_UP_DATE_REQUEST, hoursUpDateRequest, api, authApi),
    takeLatest(ExpertConsultTypes.INVESTOR_IMPROVE_HOURS_REQUEST, investorImproveHoursRequest, api, authApi),
    takeLatest(ExpertConsultTypes.INVESTOR_EXTEND_AGREEMENT_REQUEST, investorExtendAgreementRequest, api, authApi),
    takeLatest(ExpertConsultTypes.INVESTOR_EDIT_EXTENSION_REQUEST, investorEditExtensionRequest, api, authApi),
    takeLatest(ExpertConsultTypes.ADVISOR_SIGN_EXTENSION_REQUEST, advisorSignExtensionRequest, api, authApi),
    takeLatest(ExpertConsultTypes.GET_AGREEMENT_REQUEST, getAgreementRequest, api, authApi),
    takeLatest(ExpertConsultTypes.GET_DATA_FOR_AGREEMENT_REQUEST, getDataForAgreementRequest, api, authApi),
    takeLatest(ExpertConsultTypes.AGREEMENT_DATA_UPDATE_REQUEST, agreementDataUpdateRequest, api, authApi),
    takeLatest(ExpertConsultTypes.NOTIFICATION_VIEWED_REQUEST, notificationViewedRequest, api, authApi),
    takeLatest(ExpertConsultTypes.EXPERT_CONSULT_ACTION_HISTORY_REQUEST, expertConsultActionHistoryRequest, api, authApi),
    takeLatest(ExpertConsultTypes.INVESTOR_UPLOAD_AGREEMENT_REQUEST, uploadAgreementByInvestor, api, authApi),
    takeLatest(ExpertConsultTypes.INVESTOR_REUPLOAD_AGREEMENT_REQUEST, reuploadAgreementByInvestor, api, authApi),
    takeLatest(ExpertConsultTypes.ADVISOR_UPLOAD_AGREEMENT_REQUEST, uploadAgreementByAdvisor, api, authApi),
    takeLatest(ExpertConsultTypes.ADVISOR_ENTER_OPTIONS_REQUEST, advisorEnterOptionsRequest, api, authApi),
    takeLatest(ExpertConsultTypes.INVESTOR_WORK_DONE_DATA_REQUEST, getWorkDoneData, api, authApi),
    takeLatest(ExpertConsultTypes.ADMIN_EDIT_DATA_REQUEST, editDataByAdmin, api, authApi),
    fork(expertConsultSyncStartMonitor),

    takeLatest(MandatesTypes.MANDATES_REQUEST, getMandates, api, authApi),
    takeLatest(MandatesTypes.DEALS_SUBMITTED_REQUEST, getDealsSubmitted, api, authApi),
    takeLatest(MandatesTypes.MANDATE_REQUEST, getMandate, api, authApi),
    takeLatest(MandatesTypes.MANDATE_SAVE_REQUEST, createMandate, api, authApi),
    takeLatest(MandatesTypes.MANDATE_NOTIFICATION_DELETE_REQUEST, deleteMandateNotification, api, authApi),
    takeLatest(MandatesTypes.DEAL_SIZE_REQUEST, getDealSize, api, authApi),
    takeLatest(MandatesTypes.MANDATE_GROUP_DATA_SAVE_REQUEST, saveMandateGroupData, api, authApi),
    takeLatest(MandatesTypes.INVESTOR_SHARED_MANDATE_REQUEST, sharedMandateByInvestor, api, authApi),
    takeLatest(MandatesTypes.PAUSE_MANDATE_REQUEST, pauseMandate, api, authApi),
    takeLatest(MandatesTypes.UNPAUSE_MANDATE_REQUEST, unpauseMandate, api, authApi),
    takeLatest(MandatesTypes.CLOSE_MANDATE_REQUEST, closeMandate, api, authApi),
    takeLatest(MandatesTypes.MANDATE_ASK_QUESTION_REQUEST, mandateAskQuestionRequest, api, authApi),
    takeLatest(MandatesTypes.MANDATE_ANSWER_QUESTION_REQUEST, answerMandateQuestion, api, authApi),
    takeLatest(MandatesTypes.MANDATE_INVITE_COLLEAGUES_REQUEST, inviteColleaguesToMandate, api, authApi),
    takeLatest(MandatesTypes.MANDATE_STATUSES_REQUEST, getMandateStatuses, api, authApi),
    takeLatest(MandatesTypes.MANDATE_ADVISOR_SHARE_STATUSES_REQUEST, getAdvisorShareStatuses, api, authApi),
    takeLatest(MandatesTypes.MANDATE_ADVISORS_SHARED_REQUEST, getMandateAdvisorsShared, api, authApi),
    takeLatest(MandatesTypes.MANDATE_SHARE_WITH_ADVISORS_REQUEST, shareMandateWithAdvisors, api, authApi),
    takeLatest(MandatesTypes.MANDATE_DEALS_MATCHES_REQUEST, getMandateDeals, api, authApi),
    takeLatest(MandatesTypes.MANDATE_STATS_REQUEST, getMandateStats, api, authApi),
    takeLatest(MandatesTypes.NOTIFICATION_MANDATE_STATUS_REQUEST, changeNotificationMandateStatus, api, authApi),
    takeLatest(MandatesTypes.MANDATE_ADD_BY_ADVISOR_REQUEST, addMandateByAdvisor, api, authApi),
    takeLatest(MandatesTypes.FIRM_MANDATES_REQUEST, getFirmMandates, api, authApi),
    takeLatest(MandatesTypes.FIRM_MANDATE_REQUEST, getFirmMandate, api, authApi),
    takeLatest(MandatesTypes.FIRM_ACTIVITIES_MANDATE_STATS_REQUEST, getFirmActivitiesMandateStats, api, authApi),
    takeLatest(MandatesTypes.FIRM_MANDATE_STATS_REQUEST, getFirmMandateStats, api, authApi),
    takeLatest(MandatesTypes.FIRM_MANDATE_DEALS_MATCHES_REQUEST, getFirmMandateDeals, api, authApi),
    takeLatest(MandatesTypes.MANDATE_QUESTION_EDIT_REQUEST, editMandateQuestion, api, authApi),
    takeLatest(MandatesTypes.MANDATE_QUESTION_DELETE_REQUEST, deleteMandateQuestion, api, authApi),
    takeLatest(MandatesTypes.MANDATE_QUESTION_PUBLISH_REQUEST, publishMandateQuestion, api, authApi),
    fork(mandatesSyncStartMonitor),

    takeLatest(MandateSharesTypes.MANDATE_SHARES_REQUEST, getMandateShares, api, authApi),
    takeLatest(MandateSharesTypes.MANDATE_SHARE_MATCH_REQUEST, getMandateMatchInfo, api, authApi),
    takeLatest(MandateSharesTypes.MANDATE_SHARES_DELETE_REQUEST, mandateSharesDeleteRequest, api, authApi),
    takeLatest(MandateSharesTypes.MANDATE_SHARES_NOTIFICATION_DELETE_REQUEST, deleteMandateSharesNotification, api, authApi),
    fork(mandateSharesSyncStartMonitor),

  ])
}
