import React, {useState, useEffect} from 'react'
import { Typography } from 'antd'
import cls from 'classname'

import MainHeaderNew from './components/MainHeaderNew.js'
import {
  CaseStudyBlock,
  TestimonialBlock,
  Footer,
  GetInTouchBlock,
  SectorsBlock,
} from './components/helpers.js'
import useAnimationEffects from './components/useAnimationEffects.js'

const {Title, Text} = Typography

function SectorsPage(props) {

  const [animate, setAnimate] = useState(false);

  //animation for bg lines and lines in getintouch block
  useAnimationEffects('secondary_page');

  useEffect(() => {
    const timerTopImageAnimation = setTimeout(() => {
      setAnimate(true);
    }, 100);

    return () => {
      if (timerTopImageAnimation) clearTimeout(timerTopImageAnimation);
    };
  }, []);

	return (
		<div className="main-page-layout sectors-page" id='scrollableElement'>
			<MainHeaderNew />
      <div className='main-content-new'>

        <div className={cls("main-top-block image sectors-page", { animate })}>
          <div className='main-top-title-block'>
            <div className='block-title case-study white'>Sectors</div>
            <Title className='title font-60 white sectors-top-title'style={{marginTop: 21}} >
              IDN Focus: Beyond Traditional <br/> Infrastructure Assets
            </Title>
          </div>
          <div className="mask"></div>
          <div className="gradient"></div>
        </div>

        <TestimonialBlock index={7} className={'sectors-page'}/>

        <SectorsBlock />

        <GetInTouchBlock index={4} />

        <Footer />

      </div>
		</div>
	)
}

export default SectorsPage;
