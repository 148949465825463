import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Link, NavLink, useHistory } from 'react-router-dom'
import cls from 'classname'
import {Layout, Divider, message} from 'antd'
import MenuComponent from './MenuComponent'
import AuthActions from '../../../Redux/AuthRedux'

import Button from '../../../Components/Button'
import { ReactComponent as Logo} from '../../../Images/landing_new/logo-new-landing.svg';
import './MainHeaderNew.less'
import { ContactFormModal } from './Modal/ContactFormModal'
import errorHandler from '../../../Utils/errorHandler'

const {Header} = Layout

function MainHeader({result, error,  ...props}) {

  const history = useHistory();
  const [animate, setAnimate] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const timerHeaderAnimation = setTimeout(() => {
      setAnimate(true);
    }, 100);

    return () => {
      if (timerHeaderAnimation) clearTimeout(timerHeaderAnimation);
    };
  }, []);

  // useEffect(() => {
  //   if (error) {
  //     errorHandler(error, props.resetError);
  //   }
  // }, [error]);

  return (
    <Header className={cls("main-header-new", { animate })}>
      <div className='header-content-wrapper desktop'>
        <Link className="logo-link-new" to="/">
          <Logo className='main-logo'/>
        </Link>

        <div className='header-links-block'>
          <Link  to="/companies">
            <div className='header-text-link'>Companies</div>
          </Link>
          <Link  to="/advisors">
            <div className='header-text-link'>M&A Advisors</div>
          </Link>
          <Link  to="/industry-executives">
            <div className='header-text-link'>Industry Executives</div>
          </Link>
          <Link  to="/investors">
            <div className='header-text-link'>Investors</div>
          </Link>
          <Divider type="vertical" style={{borderColor: '#BDBDBD'}} />
          <Link  to="/track-record">
            <div className='header-text-link'>Track Record</div>
          </Link>
          {/* <Link  to="/sectors">
            <div className='header-text-link'>Sectors</div>
          </Link> */}
          {/* <Link  to="/news">
            <div className='header-text-link'>News</div>
          </Link> */}
        </div>

        <div className='header-buttons-block desktop'>
          {/* <a href="mailto:info@idn.ltd"> */}
            <Button
              type="default"
              text='Contact us'
              className='landing'
              onClick={()=> setIsModalOpen(true)}
            />
          {/* </a> */}
          <Button
            type="primary"
            className='landing primary-green'
            text={'Login'}
            style={{width: 104}}
            onClick={()=> history.push('/login')}
          />
        </div>
        <div className='header-buttons-block mobile'>
          <MenuComponent setIsModalOpen={setIsModalOpen} />
        </div>
      </div>
      <ContactFormModal
        setIsModalOpen={setIsModalOpen}
        isModalOpen={isModalOpen}
      />

    </Header>
  )
}

const mapStateToProps = (state) => {
  return {
    error: state.auth.error,
  }
}

const mapDispatchToProps = (dispatch) => ({
  resetError: () => dispatch(AuthActions.authErrorReset()),
})

export default connect(mapStateToProps, mapDispatchToProps)(MainHeader)
