import React, {useState, useEffect} from 'react'
import { Typography } from 'antd'
import cls from 'classname'

import MainHeaderNew from './components/MainHeaderNew.js'
import {
  TestimonialBlock,
  Footer,
  GetInTouchBlock,
  TrackRecordsBlock,
} from './components/helpers.js'
import useAnimationEffects from './components/useAnimationEffects.js'

const {Title, Text} = Typography

function TrackRecordPage(props) {

  const [animate, setAnimate] = useState(false);

  //animation for bg lines and lines in getintouch block
  useAnimationEffects('secondary_page');

  useEffect(() => {
    const timerTopImageAnimation = setTimeout(() => {
      setAnimate(true);
    }, 100);

    return () => {
      if (timerTopImageAnimation) clearTimeout(timerTopImageAnimation);
    };
  }, []);

	return (
		<div className="main-page-layout track-record-page" id='scrollableElement'>
			<MainHeaderNew />
      <div className='main-content-new'>

        <div className={cls("main-top-block image track-record", { animate })}>
          <div className='main-top-title-block'>
            <div className='block-title case-study white'>Track Record</div>
            <Title className='title font-60 white track-record-top-title'style={{marginTop: 21}} >
              A proven track-record of facilitating <br className='ignore-br-1330'/> successful transactions across Europe
            </Title>
          </div>
          <div className="mask"></div>
          <div className="gradient"></div>
        </div>

        <TestimonialBlock index={8} className={'track-record'} />

        <TrackRecordsBlock />

        <GetInTouchBlock index={5} />

        <Footer />

      </div>
		</div>
	)
}

export default TrackRecordPage;
