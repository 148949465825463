import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
// import {useSelector} from 'react-redux'
import cls from 'classname'
import { Typography, Divider, Carousel, message } from 'antd'

import { ReactComponent as IconForCompanies } from '../../../Images/landing_new/for_companies.svg';
import { ReactComponent as IconForAdvisers } from '../../../Images/landing_new/for_advisers.svg';
import { ReactComponent as IconForInvestors } from '../../../Images/landing_new/for_investors.svg';
import { ReactComponent as IconForIndustry } from '../../../Images/landing_new/for_industry.svg';
import { ReactComponent as Arrow} from '../../../Images/landing_new/arrow.svg';
import { ReactComponent as Linked} from '../../../Images/landing_new/linked.svg';
import Images from '../../../Images';
import useAnimationEffects_2 from './useAnimationEffects_2';
import { ContactFormModal } from './Modal/ContactFormModal';
// import '../MainPageNew.less'

const {Title, Text} = Typography

export const ServicesBlock = () => {

  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 576);

  const data = [
    {
      title: <div className='text'>For <br/>Companies</div>,
      subTitle: 'IDN connects companies with investors to accelerate growth, sell shares, and divest assets.',
      icon: <IconForCompanies className="icon" />,
    },
    {
      title: <div className='text'>For M&A <br/>Advisors</div>,
      subTitle: 'IDN selectively introduces investors to M&A advisors, enhancing sell-side and securing buy-side mandates.',
      icon: <IconForAdvisers className="icon" />,
    },
    {
      title: <div className='text'>For Industry<br/> Executives</div>,
      subTitle: 'IDN secures PE buy-side mandates to senior executives with actionable investment ideas.',
      icon: <IconForIndustry className="icon" />,
    },
    {
      title: <div className='text'>For PE <br/>Investors</div>,
      subTitle: 'IDN connects investors with high-quality, infrastructure-like opportunities across Europe.',
      icon: <IconForInvestors className="icon" />,
    },
  ]

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 576);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
    {!isSmallScreen ?
      <div className='service-block-wrapper'>
        {data.map((el, i) => (
          <div key={i} className='service-block-item'>
            <div className='arrow-wrapper'>
              <div className='learn-more'>Learn more</div>
              <Arrow className="arrow" />
            </div>
            {el.icon}
            {el.title}
            <div className='sub-text'>{el.subTitle}</div>
          </div>
        ))}
      </div>
      :
        <div className='service-block-carousel-wrapper'>
          <Carousel
            className='carousel-custom'
            slidesToShow={1}
            // centerMode={true}
            // centerPadding="20px"
          >
            {data.map((el, i) => (
              <div className='carousel-item-wrapper' key={i}>
                <div  className='service-block-item-carousel like-hover'>
                  <div className='arrow-wrapper'>
                    <div className='learn-more'>Learn more</div>
                    <Arrow className="arrow" />
                  </div>
                  {el.icon}
                  {el.title}
                  <div className='sub-text'>{el.subTitle}</div>
                </div>
              </div>
            ))}
          </Carousel>
        </div>
      }
    </>
  )
}


export const UniqueBlock = ({index, setActiveSlide, maxSlidesIndex, sliderElementRef}) => {

  const data = [
    {
      title: 'Our Network',
      text_1: 'IDN is connected to hundreds of leading private equity investment firms and corporates investing in infrastructure-related assets across Europe.',
      text_2: 'Through our proprietary platform, we serve as a centralised, specialised hub, seamlessly connecting all relevant parties seeking and providing capital.',
      image: Images.slide_1,
    },
    {
      title: 'Business model',
      text_1: 'IDN operates predominantly on the buy-side, where we are engaged and compensated by private equity investors.',
      text_2: 'This approach incentivizes companies and advisors to collaborate with us, offering streamlined access to leading investors and enabling highly targeted, strategic introductions.',
      image: Images.slide_2,
    },
    {
      title: 'Proprietary Technology',
      text_1: "IDN's proprietary online platform streamlines interactions between advisors, senior executives, and investors, creating a secured environment for engaging in M&A activities.",
      text_2: 'Our technology enables targeted and efficient matchmaking, enhancing the visibility and accessibility of key investment opportunities to qualified private equity investors.',
      image: Images.slide_3,
    },
    {
      title: 'Legal Framework',
      text_1: 'Our robust legal framework supports advisors and senior executives in engaging with investment firms on buy-side mandates.',
      text_2: 'This streamlined approach simplifies onboarding, minimizes transactional risks, and ensures a compliant, seamless pathway for all counterparties, facilitating effective and transparent engagements.',
      image: Images.slide_4,
    },
  ]

  return (
    <div className='main-unique-block animated-block' ref={sliderElementRef}>
      <div className='left-square-bg'></div>

      <div className='mobile-flex-container'>
        <div className='slider-img-wrapper'>
          <img src={data[index]?.image} alt='' />
        </div>
        <div className='content-wrapper'>
          <div className='arrow-slider-unique-block-wrapper'>
            <ButtonWithArrow
              text={null}
              className={'arrow-slider-unique-block'}
              onClick={()=> setActiveSlide((prev) => (prev > 0 ? prev - 1 : maxSlidesIndex))}
            />
            <ButtonWithArrow
              text={null}
              className={'arrow-slider-unique-block'}
              onClick={()=> setActiveSlide((prev) => (prev < maxSlidesIndex ? prev + 1 : 0))}
            />
          </div>
          <div className='block-title white unique'>Our Unique Approach</div>

          <div className='content-title white'>
            {data[index]?.title}
          </div>
          <div className='content-text white'>
            {data[index]?.text_1}
            <br />
            <br />
            {data[index]?.text_2}
          </div>
          <div className='custom-dots-wrapper'>
          {data.map((el, i)=> (
              <div
              key={i}
              className='click-wrapper'
              //  className={cls('custom-dot', {'active': i === index})}
              onClick={()=> setActiveSlide(i)}
              >
                <div className={cls('custom-dot', {'active': i === index})}></div>
              </div>
          ))}
          <div className='counter'>{`0${index + 1}/0${data.length}`}</div>
          </div>
        </div>
      </div>
      <div className='right-square-bg'></div>
    </div>
  )
}

export const GetInTouchBlock = ({index}) => {

  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 576);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const data = [
    {//main-page
      title: 'Get in touch',
      text: isSmallScreen ?
          <span>
            Partner with IDN to <br/> access capital,
            secure mandates, and unlock <br/>
            <span className='text-orange'>
              investment <br/> opportunities
            </span>
          </span>
        :
          <span>
            Partner with IDN to access capital, <br/>
            secure mandates, and unlock new <br/>
            <span className='text-orange'>
              investment opportunities
            </span>
          </span>
        ,
    },
    {
      title: 'Get in touch',
      text:
        <span>
          Partner with a trusted firm to secure <br className="ignore-br-1330"/>
          capital and drive your <br/>
          <span className='text-orange'>
            strategic goals
          </span>
        </span>,
    },
    {//advisors-page
      title: 'Get in touch',
      text:
        // <span>
        //   Interested in unlocking access to dozens <br/>
        //   of Tier-1 PE investors while <br/>
        //   <span className='text-orange'>
        //     maximising returns?
        //   </span>
        // </span>,
        <span>
          Partner with IDN to unlock access to leading <br className="ignore-br-1330"/>
          PE infrastructure investors while <br className="ignore-br-1330"/>
          <span className='text-orange'>
            maximising returns
          </span>
        </span>,
    },
    {//investors
      title: 'Get in touch',
      text:
        // <span>
        //   Ready to partner with IDN to boost your origination <br/>
        //   capabilities and achieve your strategic <br/>
        //   <span className='text-orange'>
        //     investment goals?
        //   </span>
        // </span>,
        <span>
          Partner with IDN to boost your origination <br/>
          capabiliities and unlock bilateral <br/>
          <span className='text-orange'>
            investment opportunities
          </span>
        </span>,
    },
    {//sectors
      title: 'Get in touch',
      text:
        <span>
          You want to know whether infrastructure investors <br/>
          could be a relevant buyer or source of <br/>
          <span className='text-orange'>
            capital for your business?
          </span>
        </span>,
    },
    {//track-record
      title: 'Get in touch',
      text:
        <span>
          Leverage IDN's network <br/>
          to close your next  <br/>
          <span className='text-orange'>
            transaction
          </span>
        </span>,
        // <span>
        //   Read to leverage IDN's expertise and <br/>
        //   network to close your next <br/>
        //   <span className='text-orange'>
        //     transaction?
        //   </span>
        // </span>,
    },
    {//industry-executives
      title: 'Get in touch',
      text:
        <span>
          Partner with IDN to secure <br className='ignore-br-1330'/>
          buy-side mandates from <br/>
          <span className='text-orange'>
            PE investors
          </span>
        </span>,
    },
  ]

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 576);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className='main-get-in-touch-block animated-block' id='intouch-block'>
      <div className='third-square'></div>
      <div className='block-title get-in-touch'>{data[index].title}</div>
      <Title className='title font-60 white get-in-touch' style={{textAlign: 'center'}}>
        {data[index].text}
      </Title>
      <div className='contact-us-wrapper' onClick={()=> setIsModalOpen(true)}>
        <div className='contact-us'>Contact us</div>
        <Arrow className="arrow" />
      </div>
      <ContactFormModal
        setIsModalOpen={setIsModalOpen}
        isModalOpen={isModalOpen}
      />
    </div>
  )
}

export const ButtonWithArrow = ({text, className, style, onClick}) => {

  return (
    <div className={cls('button-with-arrow-wrapper', {[className]: className})} style={{cursor: 'pointer', ...style}} onClick={onClick}>
      <div>{text}</div>
      <Arrow className="arrow" />
    </div>
  )
}

export const Footer = () => {

  return (
    <div className='footer-wrapper'>
      <div className='footer-first-row-wrapper'>
        <div className='footer-block rights'>
          <div className='footer-text rights'>
            2025 <span className='hide-ltd'>The Infrastructure Deals Network Ltd,</span> All rights reserved
            <div className='link-linkedin-mobile' >
              <a
                href="https://www.linkedin.com/in/enrico-cattabiani-73641780/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Linked style={{cursor: 'pointer'}}/>
              </a>
            </div>
          </div>
        </div>

        <div className='footer-block get-in-touch'>
            <div className='footer-block links'>
              <div className='footer-text link'>
                <Link to="/privacy-policy">Privacy policy</Link>
              </div>
              <div className='footer-text link'>
                <Link to="/cookies-policy">Cookie policy</Link>
              </div>
              <div className='footer-text link'>
                <Link to="/terms-of-use">Terms of Use</Link>
              </div>
            </div>
        </div>
        <div className='footer-block right'>
          <div className='footer-text follow-us'>
            Follow us  on  LinkedIn
          </div>
          <a
            href="https://www.linkedin.com/company/idn-the-infrastructure-deals-network/?viewAsMember=true"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Linked className="icon-linked" style={{cursor: 'pointer'}}/>
          </a>
        </div>
      </div>

      <div className='adress'>162-168 Regent Street, London, United Kingdom</div>
    </div>
  )
}


export const SecondaryPageTopBlock = ({index}) => {

  const [animate_bg, setAnimate_bg] = useState(false);
  const [animate_img, setAnimate_img] = useState(false);

  //animation for top block
  useAnimationEffects_2(setAnimate_bg, setAnimate_img);

  const data = [
    {
      title: 'For Companies',
      text_1: 'Your Trusted Partner to Access Private Equity Capital',
      text_2: 'We connect company managements and shareholders with Tier-1 private equity infrastructure funds and strategic investors, providing access to capital for growth, share sales, and strategic asset divestitures—all tailored to support your objectives. While typically positioned on the buy-side, IDN remains flexible to meet your specific transaction needs.',
      image: Images.image_companies,
    },
    {
      title: 'For M&A Advisors',
      text_1: 'Access leading infrastructure funds to secure and strengthen mandates',
      text_2: "As an infrastructure specialist, IDN selectively connects M&A advisors with leading PE infrastructure investors and corporates across Europe. While IDN is typically positioned on the buy-side, we remain flexible to meet each advisor's and transaction's needs.",
      image: Images.imageAdvisors,
    },
    {
      title: 'For Senior Industry Executives',
      text_1: 'Securing Private Equity Mandates for Senior Industry Executives',
      text_2: 'IDN partners with senior industry executives who provide proprietary transaction ideas and unique insights into potential acquisition targets for private equity investors. On their behalf, IDN secures buy-side mandates with Tier-1 PE investment firms to execute these opportunities, which may also lead to C-level or Board appointments post-acquisition.',
      image: Images.image_industry,
    },
    {
      title: 'For Investors',
      text_1: 'Connecting Investors to Infrastructure Transactions Across Europe',
      text_2: 'IDN primarily connects investors with high-quality, under-the-radar infrastructure opportunities across Europe, which we originate by leveraging our extensive network of companies, advisors, and senior industry executives.',
      image: Images.image_investors,
    },
  ]

  return (
    <div className={cls("secondary-page-top-block", { animate_bg })}>
      <div className='square-bg left'></div>
      <div className={cls("img-wrapper", { animate_img })}>
        <img src={data[index]?.image} alt='' />
        <div className="mask_img"></div>
      </div>
      <div className='content-wrapper'>

        <div className='block-title white secondary-top-block'>{data[index]?.title}</div>

        <div className={cls('content-title white fs-60 top-block', {'fs-54': index === 3})}>
          {data[index].text_1}
        </div>
        <div className='content-text white'>
          {data[index].text_2}
        </div>

      </div>
      <div className='square-bg right'></div>
      <div className="mask"></div>
    </div>
  )
}

export const CaseStudyBlock = ({index}) => {

  const data = [
    {
      title: 'Case Study',
      title_1: <span>Supporting Quantitas Energy <br className="ignore-br-1330"/> AS in Securing Strategic<br className="ignore-br-1330"/> Growth Capital</span>,
      text_1: 'Quantitas Energy AS, a Battery Energy Storage System (BESS) developer with the ambition to become a leading asset owner and operator in the German BESS market, was looking for an investor sharing its vision and able to provide significant capital along the way to build out its project pipeline.',
      image: Images.case_study_4,
      approach: "Leveraging our in-depth market knowledge and strong network, IDN introduced Quantitas to Vitol-backed VPI. This connection was facilitated by IDN's extensive relationships with top-tier investors and a clear understanding of Quantitas's strategic goals.",
      outcome: "Quantitas successfully secured a €450 million equity commitment from Vitol-backed VPI, enabling it to advance its ambitious growth plans and strengthen its position as a BESS market leader.",
      link: 'https://www.linkedin.com/feed/update/urn:li:activity:7240390622434492416',
    },
    {
      title: 'Case Study',
      title_1: <span>Facilitating a Strategic Sale <br className="ignore-br-1330"/> of Biomethane Projects</span>,
      text_1: "Enexon, an advisory firm, was engaged by HBA, a company developing biomethane plants, to assist in a strategic sale of some projects in its pipeline . Enexon sought IDN's help in identifying a suitable investor with a strong interest in renewable energy and biomethane projects.",
      image: Images.case_study_2,
      approach: "IDN leveraged its network to connect Enexon and HBA with Verdalia, a Goldman Sachs-backed bioenergy firm. By understanding Enexon's mandate and HBA's project potential, IDN facilitated an introduction that matched HBA's objectives with Verdalia's strategy.",
      outcome: "Enexon's client successfully completed the transaction with Verdalia, securing a buyer equipped to further develop and scale its biomethane projects.",
      link: 'https://www.linkedin.com/feed/update/urn:li:activity:7224767351622164480'
    },
    {
      title: 'Case Study',
      title_1: <span>Securing a Buy-Side <br className="ignore-br-1330" /> Mandate with FIEE in Spain</span>,
      text_1: "Fernando Celaya, a senior industry executive and trusted Senior Advisor in IDN's network, identified a promising investment opportunity in Cubierta Solar, a commercial and industrial PV rooftop company in Spain. Seeing alignment with IDN's key sectors of focus, he confidentially consulted with IDN to explore the best approach for engaging its private equity clients.",
      image: Images.case_study_3,
      approach: 'Leveraging its deep understanding of the investment criteria of its private equity partners, IDN proposed this opportunity to FIEE, a specialized energy investor keen to enter the Spanish market. IDN secured a buy-side mandate for Fernando, facilitating direct engagement with FIEE through its proprietary platform.',
      outcome: "FIEE successfully acquired a stake in Cubierta Solar, marking its entry into the Spanish market. This transaction highlighted IDN's capability to connect senior executives with investment opportunities and strategic partners that align with their market insights.",
      link: 'https://www.linkedin.com/posts/enrico-cattabiani-73641780_fiee-sgr-primo-investimento-in-spagna-nel-activity-7090662831439626240-nx7B?utm_source=share&utm_medium=member_desktop'
    },
  ]

  return (
    <div className='case-study-block animated-block'>
      <div className='content-wrapper'>
        <img src={data[index].image} alt='' className='img-case-study desktop'/>
        <div className='info-wrapper'>
          <div className='block-title case-study'>{data[index].title}</div>
          <Title className='title' style={{marginTop: 22}}>{data[index].title_1}</Title>
          <div className='text-1'>{data[index].text_1}</div>
          <img src={data[index].image} alt='' className='img-case-study mobile'/>
          <div className='approach-outcome-block'>
            <div className='left-part'>
              <div className='sub-title-wrapper'>
                <img src={Images.approach} alt='' width={21}/>
                <Title className='title sub'>Our Approach</Title>
              </div>
              <div className='text-wrapper'>
                {data[index].approach}
              </div>
            </div>
            <div className='right-part'>
              <div className='sub-title-wrapper'>
                <img src={Images.outcome} alt='' width={21}/>
                <Title className='title sub'>Outcome</Title>
              </div>
              <div className='text-wrapper'>
                {data[index].outcome}
              </div>
            </div>

          </div>
          <div className='bottom-link-wrapper'>
            <div>
              If you want learn more about this deal
            </div>
            <a href={data[index].link} target="_blank" rel="noopener noreferrer" style={{width: '100%'}}>
              <ButtonWithArrow
                text={'Click here'}
                className={'sectors-new'}
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export const TestimonialBlock = ({index, className}) => {

  const data = [
    {
      title: 'CEO Testimonial',
      text: `"IDN stands out as a trusted partner for all participants in Europe's infrastructure ecosystem, facilitating M&A and capital-raising activities by connecting companies, advisors, and senior executives with leading investors through our specialised expertise and technology-enabled platform."`,
      image: Images.ceo,
      name: 'Enrico Cattabiani',
      role: "IDN's CEO",
      link: 'https://www.linkedin.com/in/enrico-cattabiani-73641780/',
    },
    {
      title: 'CEO Testimonial',
      text: `"IDN's in-depth knowledge and tailored approach were invaluable in connecting us with Vitol-backed VPI, an investor fully aligned with our vision and ambitions to become the leading BESS platform in Germany. Their expertise and extensive network helped secure the capital needed to execute our growth plans."`,
      image: Images.teodor,
      name: 'Dr. Theodor Borsche',
      role: 'Managing Director at VPI FlexKraft GmbH',
      link: 'https://www.linkedin.com/in/theodor-borsche/',
    },
    {
      title: 'Advisor Testimonial',
      text: `"Working with IDN was instrumental in finding the ideal investor for our client's biomethane projects. IDN's access to selected, Tier-1 investors like Verdalia, added significant value to our advisory mandate, enabling a successful transaction that met our client's strategic goals."`,
      image: Images.stefano,
      name: 'Stefano Madeddu',
      role: 'CEO / Board Member',
      link: 'https://www.linkedin.com/in/stefano-madeddu/',
    },
    {
      title: 'Senior Advisor Testimonial',
      text: `"Through IDN's secured platform and extensive connections with leading private equity firms, I was able to secure a buy-side mandate from an opportunity I identified, ultimately leading to significant returns."`,
      image: Images.fernando,
      name: 'Fernando Celaya',
      role: 'Former Managing Director at Abengoa',
      link: 'https://www.linkedin.com/in/fernando-celaya-prieto/',
    },
    {    //investors_page
      title: 'Seller Testimonial',
      text: `“IDN's expertise and strong international network were instrumental in connecting us with the right investor, Lazard AM, whom we would otherwise not have had on the list of parties to reach out for this specific business in the DACH region. Their support throughout the process ensured building trust early on and contributed to a smooth, successful transaction, allowing us to achieve an optimal outcome.”`,
      image: Images.bernhard,
      name: 'Bernhard Raberger',
      role: 'Managing Partner, BlueMinds',
      link: 'https://www.linkedin.com/in/bernhard-raberger-b8b3baaa/?originalSubdomain=at',
    },
    {
      title: 'Investor Testimonial',
      text: `“IDN's proactiveness in engaging its extensive network of senior executives in the telecom industry and its understanding of our investment goals were fundamental to connect us with GCN. IDN and its local Senior Advisor supported us in building a line of trust with GCN's owner, which contributed to the successful outcome of this transaction”`,
      image: Images.samir,
      name: 'Samir Assad',
      role: 'Operating Advisor at I Squared Capital & former Head of M&A at EXA Infrastructure',
      link: 'https://www.linkedin.com/in/samir-assaad-943b5/',
    },
    {
      title: 'Investor Testimonial',
      text: `"IDN introduced us to Senior Advisor with strong sector knowledge of the UK data centre market. His insights were very helpful in our due diligence."`,
      image: Images.fiona,
      name: 'Fiona van Limburg Stirum',
      role: 'Vice President at ISQ Capital',
      link: 'https://www.linkedin.com/in/fiona-van-limburg-stirum-11b10439/?originalSubdomain=uk',
    },
    {
      title: 'Our focus',
      text: `Infrastructure investors today are increasingly interested in companies that transcend traditional assets, prioritizing businesses with defensible and recurring business models that provide critical services to society or their industry.`,
      image: null,
      name: null,
      role: null,
      link: null,
    },
    {
      title: 'Transactions',
      text: `IDN successfully facilitated transactions with leading private equity and strategic investors across the energy, transport, and telecom sectors in multiple European countries, delivering significant value to all parties involved.`,
      image: null,
      name: null,
      role: null,
      link: null,
    },

  ]

  return (
    <div className={cls('testimonial-block', {'animated-block': ![4,5,6].includes(index)}, {[className]: className} )}>
      <div className='block-title testimonial'>{data[index].title}</div>
      <div className={cls('block-text testimonial', {[className]: className})}>
        {data[index].text}
      </div>
      {data[index].image ?
        <>
          <img src={data[index].image} alt='' width={80} className='testimonial-img link'/>
          <div className='block-text name'>{data[index].name}</div>
          <div className='block-text role'>{data[index].role}</div>
        </>
      : null}
      {data[index].link
        ?
          <a href={data[index].link}
             target="_blank"
             rel="noopener noreferrer"
          >
            <Linked className="icon-linked" style={{cursor: 'pointer'}}/>
          </a>
        :
          null
      }
    </div>
  )
}

export const CollapsedBlock = () => {

  const [openItems, setOpenItems] = useState([]);

  const toggleItem = (index) => {
    setOpenItems(prev =>
      prev.includes(index)
        ? prev.filter(i => i !== index)
        : [...prev, index]
    );
  };

  const data = [
    {
      title: <>Opportunistic <br/><span className='text-orange'>Deals Access</span></>,
      text: `Gain direct, one-on-one access to high-quality deals sourced by IDN through its network of companies, advisors, and executives.`,
    },
    {
      title: <>Proactive <br/><span className='text-orange'>Deal Search</span></>,
      text: `Proactively leverage IDN's network to originate and gain exclusive access to opportunities aligned with your specific mandates.`,
    },
    {
      title: <>Advisors' <br/><span className='text-orange'>Placements</span></>,
      text: `Leverage IDN's network to access top-tier advisors for due diligence and board roles, ensuring strategic expertise and informed decision-making`,
    },
  ]

  return (
    <div className='what-we-offer-block animated-block investors-page'>
      <div className='block-title'>What we offer</div>
      <Title className='title investors-page'>
        We offer three distinct services to meet the needs of our private equity clients and their portfolio companies:
      </Title>

      {data.map((el, i)=> {
        const isOpen = openItems.includes(i);
          return (
            <div key={i}>
              <div className='collapse-header'>
                <Title className='title white'>{el.title}</Title>
                <Text className='text white'>{el.text}</Text>
                <ButtonWithArrow
                  text={null}
                  className={cls('arrow-collapse', {'open': isOpen})}
                  onClick={()=> toggleItem(i)}
                />
              </div>
              <div className={cls('collapse-content ', {'open': isOpen})}>
                <CaseStudyBlockInvestorsPage index={i} open={cls({'open': isOpen})}/>
                <TestimonialBlock index={i + 4} className={'investors-page'}/>
              </div>
            </div>
          )
        }
        )
      }
    </div>
  )
}


export const CaseStudyBlockInvestorsPage = ({index, open}) => {

  const data = [
    {
      title: 'Case Study',
      title_1: <span>Acquisition of Collective Energy</span>,
      text_1: 'Through its extensive network, IDN identified an investment opportunity in Collective Energy (CE), a company specializing in community-based sustainable energy projects. The majority shareholder was seeking an exit, presenting an attractive opportunity for the right investor.',
      image: Images.case_study_1,
      approach: "By leveraging our understanding of the investment criteria and objectives of our private equity partners, we connected CE's shareholders with a leading infrastructure investor specializing in sustainable energy.",
      outcome: "The investor successfully acquired 100% of CE's share capital and is now focusing on expanding its platform, supporting CE's mission of driving growth in C&I solar rooftop projects.",
      link: 'https://www.linkedin.com/feed/update/urn:li:activity:7199012586028224512',
    },
    {
      title: 'Case Study',
      title_1: <span>Supporting I Squared Capital <br/> in expanding EXA Infrastructure</span>,
      text_1: "I Squared Capital (ISQ) - owned EXA Infrastructure (\"EXA\"), a leading digital infrastructure company, aimed to expand its fiber optic network in the CEE and Balkan regions through bolt-on acquisitions. ISQ engaged IDN to proactively source and introduce potential targets that fit EXA's strategic objectives.",
      image: Images.case_study_5,
      approach: "IDN leveraged its network of over 200 senior industry executives in the telecom industry to identify proprietary, off-market investment opportunities. We presented and introduced nine targeted opportunities to the ISQ and EXA investment teams, each in principle aligned with their expansion strategy.",
      outcome: "EXA successfully acquired Global Communication Net (GCN) a Bulgarian telecommunication company introduced by IDN, enhancing its fiber optic network and supporting its growth objectives.",
      link: 'https://www.linkedin.com/feed/update/urn:li:activity:7237411423155326976'
    },
    {
      title: 'Case Study',
      title_1: <span>Senior Advisory placement <br/> for I Squared Capital</span>,
      text_1: "ISQ's investment team was considering an investment opportunity in Proximity Data Centres, a leading data centre operator in the UK, on behalf of its portfolio company nLighten. The investment team asked IDN to introduce a Senior Advisor from our network with strong experience in the UK data centre industry to support them during the due diligence process.",
      image: Images.case_study_6,
      approach: 'IDN tapped into its extensive network of telecom infrastructure advisors in the UK to select the most suitable candidate for this specific mandate. We proposed several highly qualified options to the ISQ team.',
      outcome: "ISQ engaged one of IDN's Senior Advisor, an ex-CEO in the UK data centre industry with excellent knowledge of the target company. IDN's Senior Advisor supported the investment team throughout the due diligence, which was successfully concluded with nLighten's acquisition of Proximity Data Centres.",
      link: ''
    },
  ]

  return (
    <div className={cls('case-study-block investors-page', {'open': open })}>
      <div className='content-wrapper'>
        <img src={data[index].image} alt='' className='img-case-study desktop'/>
        <div className='info-wrapper'>
          <div className='block-title case-study'>{data[index].title}</div>
          <Title className='title ' style={{marginTop: 22}}>{data[index].title_1}</Title>
          <div className='text-1'>{data[index].text_1}</div>
          <img src={data[index].image} alt='' className='img-case-study mobile'/>
          <div className='approach-outcome-block'>
            <div className='left-part'>
              <div className='sub-title-wrapper'>
                <img src={Images.approach} alt='' width={21}/>
                <Title className='title sub'>Our Approach</Title>
              </div>
              <div className='text-wrapper'>
                {data[index].approach}
              </div>
            </div>
            <div className='right-part'>
              <div className='sub-title-wrapper'>
                <img src={Images.outcome} alt='' width={21}/>
                <Title className='title sub'>Outcome</Title>
              </div>
              <div className='text-wrapper'>
                {data[index].outcome}
              </div>
            </div>

          </div>
          {data[index].link ?
            <div className='bottom-link-wrapper'>
            <div>
              If you want learn more about this deal
            </div>
            <a href={data[index].link} target="_blank" rel="noopener noreferrer" style={{width: '100%'}}>
              <ButtonWithArrow
                text={'Click here'}
                className={'sectors-new'}
              />
            </a>
          </div>
          : null}
        </div>
      </div>
    </div>
  )
}

export const SectorsBlock = ({index, open}) => {
  const [active, setActive] = useState(false);

  const data = [
    {
      title: <span>Energy & <br /> Environment</span>,
      image: Images.sector_1w,
      subtitles: ['Clean generation & storage', 'Energy efficiency', 'Waste & circular economy', 'Utilities & Trad. Infrastructure'],
      icons: ['wind', 'energy', 'bin', 'infr'],
    },
    {
      title: <span>Transport & <br /> Logistics</span>,
      image: Images.sector_2w,
      subtitles: ['Logistics & storage', 'Clean mobility', 'Fleets decarbonization', 'Trad. Transport infrastructure'],
      icons: ['wheel', 'mobility', 'fleets', 'bridge2'],
    },
    {
      title: <span>Telecom & <br/> Data Infrastructure</span>,
      image: Images.sector_3w,
      subtitles: ['Fibre connectivity', 'Towers & 5G-related', 'Data centres', 'New telco infrastructure'],
      icons: ['earth', 'i5g', 'cloud', 'sputnic'],
    },
    {
      title: <span>Social & <br/> Healthcare</span>,
      image: Images.sector_4w,
      subtitles: ['Healthcare clinics', 'Education businesses', 'Elderly & social care', 'Social Infra PPPs'],
      icons: ['home', 'comp', 'heart', 'circle_heart'],

    },
  ]

  return (
    <div className='sectors-page-sectors-block animated-block'>

      <div className='sectors-title'>
        IDN is broadly interested in the following sectors:
      </div>

      <div className='sectors-scroll-container desktop' >
        <div className='sectors-wrapper'>
            {data.map((el, i) => {
              return (
                <>
                  <div key={i}
                    className={cls('sector', {'active': active === i })}
                    onClick={()=> setActive(prev => prev === i ? false : i)}
                  >
                    <img src={el.image} alt='' width={'100%'} />
                    {active === i || active === false ?
                      <div className='sector-title white'>{el.title}</div>
                      : null
                    }
                    {active === i ?
                      <div className='sector-arrow-wrapper'>
                        <ButtonWithArrow
                          text={null}
                          className={'arrow-sector-page left'}
                          onClick={(e)=> {
                            e.stopPropagation();
                            setActive((prev) => (prev > 0 ? prev - 1 : 0));
                          }}
                        />
                        <ButtonWithArrow
                          text={null}
                          className={'arrow-sector-page'}
                          onClick={(e)=> {
                            e.stopPropagation();
                            setActive((prev) => (prev < 3 ? prev + 1 : 3));
                          }}
                        />
                        <div className='counter' >{`0${i + 1}/04`}</div>
                      </div>
                      : null
                    }
                  </div>
                  {active === i ?
                    <div className={cls("sub-info-wrapper", { open: active === i })}>
                      {[0,1,2,3,].map(elem => (
                        <div className='info-item'>
                          <div className='title'>
                            {el.subtitles[elem]}
                          </div>
                          <img src={Images[el.icons[elem]]} alt='' width={34} />
                        </div>
                      ))}
                    </div>
                  : null}
                </>
              )
            }) }
        </div>
      </div>
      <div className='sectors-scroll-container mobile' >
        <div className='sectors-page-carousel-wrapper'>
          <Carousel
            className='carousel-custom'
            slidesToShow={1}
          >
            {data.map((el, i) => (
              <div className='carousel-item-wrapper' key={i}>
                <div className={cls('sector-item-carousel')}>
                  <img src={el.image} alt='' style={{height: '100%'}} />
                  <div className='sector-carousel-content-wrapper'>
                    <div className='sector-title white'>{el.title}</div>
                    <div className={cls("sub-info-wrapper")}>
                      {[0,1,2,3,].map(elem => (
                        <div className='info-item'>
                          <div className='title white'>
                            {el.subtitles[elem]}
                          </div>
                          <img src={Images[el.icons[elem]]} alt='' width={34} />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Carousel>
        </div>
      </div>

      <div className='sectors-title bodered please-note'>
        Please note: the list is non-exhaustive, and we remain interested in assessing any opportunity that might present key characteristics of infrastructure investing.
      </div>
    </div>
  )
}

export const TrackRecordsBlock = ({index, open}) => {

  const [active, setActive] = useState('all_sectors');

  const filters = [
    {label: 'All sectors', value: 'all_sectors'},
    {label: 'Energy & Environment', value: 'energy_evironment'},
    {label: 'Transport & Logistics', value: 'transport_logistics'},
    {label: 'Telecom & Data', value: 'telecom_data'},
    {label: 'Social & Healthcare', value: 'social_healthcare'},
  ]

  const data = [
    {
      icons: ['squared2', 'exa', 'divider', 'gsc'],
      sectors: ['Telecom & Data', 'Fibre optic'],
      text: "I Squared-backed EXA Infrastructure's acquisition of GCN",
      place: 'Bulgaria, October  2024',
      link: 'https://www.linkedin.com/feed/update/urn:li:activity:7237411423155326976',
    },
    {
      icons: ['dws2', 'divider', 'global'],
      sectors: ['Transport & Logistics', 'Port terminals'],
      text: "DWS Infrastructure's financing the take-private of GPH",
      place: 'United Kingdom, August  2024',
      link: 'https://www.linkedin.com/feed/update/urn:li:activity:7264585523606781952',
    },
    {
      icons: ['vitol2', 'vpi','divider', 'quantitas'],
      sectors: ['Energy & Environment', 'Battery storage'],
      text: "Vitol's backed VPI investment in Quantitas Energy",
      place: 'Germany, July  2024',
      link: 'https://www.linkedin.com/feed/update/urn:li:activity:7240390622434492416',
    },
    {
      icons: ['goldman2', 'verdalia', 'divider', 'hba'],
      sectors: ['Energy & Environment', 'Biomethane'],
      text: "Verdalia's acquisition of four biomethane projects from HBA",
      place: 'Italy, July  2024',
      link: 'https://www.linkedin.com/feed/update/urn:li:activity:7224767351622164480',
    },
    {
      icons: ['lazard2', 'divider', 'collective'],
      sectors: ['Energy & Environment', 'C&I PV rooftop'],
      text: "Lazard AM's acquisition of Collective Energy",
      place: 'Austria, June 2024',
      link: 'https://www.linkedin.com/feed/update/urn:li:activity:7199012586028224512',
    },
    {
      icons: ['foee', 'divider', 'cubierta'],
      sectors: ['Energy & Environment', 'C&I PV rooftop'],
      text: "FIEE's acquisition of a controlling stake in Cubierta Solar",
      place: 'Spain, July  2023',
      link: 'https://www.linkedin.com/posts/enrico-cattabiani-73641780_fiee-sgr-primo-investimento-in-spagna-nel-activity-7090662831439626240-nx7B?utm_source=share&utm_medium=member_desktop',
    },
  ]

  const activeLabel = filters.find(filter => filter.value === active)?.label;

  const filteredData =
    active === 'all_sectors'
      ? data
      : data.filter(item =>
          item.sectors.some(sector =>
            sector === activeLabel
          )
        );

  const [isAnimating, setIsAnimating] = useState(false);

  useEffect(() => {
    setIsAnimating(true);
    const timeout = setTimeout(() => setIsAnimating(false), 300);
    return () => clearTimeout(timeout);
  }, [active]);


  return (
    <div className='track-record-block animated-block'>

      <div className='filters-wrapper'>
        {
          filters.map((el, i) => {
            return (
              <div key={i}
                className={cls('filter-item', {'active': active === el.value})}
                onClick={()=> setActive(el.value)}
              >
                {el.label}
              </div>
            )
          })
        }
      </div>

      <div className='track-records-wrapper'>
        {
          filteredData.map((el, i) => {
            return (
              <div
                key={i}
                className={cls('track-record-item', {'hidden': isAnimating})}
              >
                <div className='top-wrapper'>
                  <div className='images-wrapper'>
                    {
                      el.icons.map(elem => (
                        <img src={Images[elem]} alt='' />
                      ))
                    }
                  </div>
                  <div className='record-place'>
                    {el.place}
                    <Divider style={{borderColor: '#26667C', margin: '6px 0 5px'}}/>
                  </div>
                </div>

                <div className='record-text white'>{el.text}</div>
                <div className='block-wrapper'>
                  <div className='tags-wrapper'>
                    {
                      el.sectors.map(elem => (
                        <div className='tag'>
                          {elem}
                        </div>
                      ))
                    }
                  </div>

                  <Divider style={{borderColor: '#26667C',  marginTop: 20}}/>
                    <a href={el.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      //  style={{width: 'fit-content'}}
                      style={{display: 'inline-block'}}
                    >
                      <ButtonWithArrow
                        text={'Learn more'}
                        className={'track-record'}
                      />
                    </a>
                </div>
              </div>
            )
          })
        }
      </div>

    </div>
  )
}