import { useEffect, useState } from "react";


const useAnimationEffects_2 = (setAnimate_bg, setAnimate_img) => {

  useEffect(() => {
    const timerTopBgAnimation = setTimeout(() => {
      setAnimate_bg(true);
    }, 100);
    const timerTopImgAnimation = setTimeout(() => {
      setAnimate_img(true);
    }, 500);

    return () => {
      if (timerTopBgAnimation) clearTimeout(timerTopBgAnimation);
      if (timerTopImgAnimation) clearTimeout(timerTopImgAnimation);
    };
  }, []);
};

export default useAnimationEffects_2;