import React, { useState } from 'react'

import MainHeaderNew from './components/MainHeaderNew.js'
import {
  CaseStudyBlock,
  TestimonialBlock,
  Footer,
  GetInTouchBlock,
  SecondaryPageTopBlock
} from './components/helpers.js'
import useAnimationEffects from './components/useAnimationEffects.js'

function CompaniesPage(props) {

  //animation for bg lines and lines in getintouch block
  useAnimationEffects('secondary_page');

	return (
		<div className="main-page-layout companies-page" id='scrollableElement'>
			<MainHeaderNew />
      <div className='main-content-new'>

        <SecondaryPageTopBlock index={0} />

        <CaseStudyBlock index={0}/>

        <TestimonialBlock index={1} />

        <GetInTouchBlock index={1} />

        <Footer />

      </div>
		</div>
	)
}

export default CompaniesPage;
